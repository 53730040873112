import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  Box,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Component, FC, ReactElement, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import EcoMapAvatarIcon from '../eco-map-avatar-icon/eco-map-avatar-icon';
import AvatarDeadIcon from '../../avatar/avatar-dead-icon/avatar-dead-icon';
import { AccordionStyle } from '../../../utils/styling/styling';
import { theme } from '../../../utils';

interface EcoMapAccordionProps extends AccordionProps {
  icon?: IconDefinition;
  image?: string;
  svg?: ReactElement;
  label?: string;
  color?: string;
  selectedColor?: string;
  background?: string;
  avatar?: { isdead?: boolean };
  removed?: boolean;
}

const EcoMapAccordion: FC<EcoMapAccordionProps> = (props) => {
  const { isdead } = props.avatar ?? { isdead: false };
  return (
    <>
      <Accordion
        {...props}
        onClick={() => {
          return;
        }}
        square
        disableGutters
        elevation={5}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          ...AccordionStyle(props.background, props.color, props.selectedColor),
        }}
      >
        <AccordionSummary
          onClick={props.onClick}
          expandIcon={
            <FontAwesomeIcon size="2x" color={props.color} icon={faCaretDown} />
          }
          sx={{
            opacity: props.removed ? 0.4 : 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {props.icon && (
              <FontAwesomeIcon
                className="fa-icon"
                color={props.color}
                icon={props.icon}
              ></FontAwesomeIcon>
            )}
            {props.svg && <Box className={'custom-icon'}>{props.svg}</Box>}
            {props.image && (
              <AvatarDeadIcon invisible={!isdead ?? true}>
                <EcoMapAvatarIcon isdead={isdead ?? false} src={props.image} />
              </AvatarDeadIcon>
            )}
            {props.label && (
              <Typography sx={{ ml: 2 }}>{props.label}</Typography>
            )}
          </Box>
        </AccordionSummary>
        {props.children}
      </Accordion>
    </>
  );
};

export default EcoMapAccordion;
