import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { parseDateString } from '../../../utils';
import { UserApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQueryClient } from 'react-query';

const OrgMessageDialog: FC = () => {
  const { newOrgMessages, i18n, authToken } = useContext(AppContext);
  const [loading, setLoading] = useState<string | undefined>(undefined);
  const [countdown, setCountdown] = useState(5);
  const [visibleMessage, setVisibleMessage] = useState<any>();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (countdown > 0) {
      const timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [countdown]);

  useEffect(() => {
    if (newOrgMessages.length > 0 && visibleMessage !== newOrgMessages[0]) {
      setCountdown(5);
      setVisibleMessage(newOrgMessages[0]);
    }
  }, [newOrgMessages]);

  const confirmMessage = async (messageId: string) => {
    setLoading(messageId);
    await UserApi.confirmOrgMessageReceipt(authToken, messageId)
      .then((res) => {
        snackbarUtils.success(res.message || i18n.success);
      })
      .finally(() => {
        setLoading(undefined);
        queryClient.invalidateQueries('entries');
      });
  };

  return (
    <Dialog open={newOrgMessages.length > 0}>
      <DialogTitle>
        {newOrgMessages.length > 1 ? i18n.newMessages : i18n.newMessage}
      </DialogTitle>
      <DialogContent>
        {visibleMessage && (
          <Card key={visibleMessage.id} sx={{ my: 1, width: '30rem' }}>
            <Box sx={{ p: 1 }}>
              <Typography sx={{ fontSize: '0.8rem' }}>
                {parseDateString(visibleMessage.creationDate)}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {visibleMessage.subject}
              </Typography>
              <Typography>{visibleMessage.message}</Typography>
            </Box>
            <Box sx={{ mb: 2, pl: 1 }}>
              <Button
                color="primary"
                variant="contained"
                id="confirmBtn"
                disabled={countdown > 0 || loading !== undefined}
                onClick={() => confirmMessage(visibleMessage.id)}
                className="formBtn"
                sx={{ color: 'white', mr: 'auto', ml: 'auto' }}
              >
                {loading === visibleMessage.id ? (
                  <CircularProgress sx={{ color: 'white' }} />
                ) : (
                  `${i18n.confirmOrgMessage}${
                    countdown > 0 ? ` (${countdown})` : ''
                  }`
                )}
              </Button>
            </Box>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrgMessageDialog;
