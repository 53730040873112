import { FormFieldType, FormFieldTextType } from '@casecare/types';
import { FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { FormApi } from '../../../../../api';
import { AppContext } from '../../../../../hooks/context';
import { FormOptions, FormDateType } from '../../../../../types';
import { TextSize } from '../../../../../types/form-text-props';
import snackbarUtils from '../../../../../utils/snackbar/snackbar-utils';
import FormCheckBox from '../form-checkbox/form-checkbox';
import FormDate from '../form-date/form-date';
import FormDropdown from '../form-dropdown/form-dropdown';
import FormGroup from '../form-group/form-group';
import FormListMultiData from '../form-list-multi-data/form-list-multi-data';
import FormList from '../form-list/form-list';
import FormRadio from '../form-radio/form-radio';
import FormText from '../form-text/form-text';
import { FormFieldStyle } from '../../../../../types/form-element-props';
import UserSelect from '../../../user-select/user-select';
import FormAutocomplete from '../form-autocomplete/form-autocomplete';
import FormWysiwyg from '../form-wysiwyg/form-wysiwyg';

interface FormProps {
  type: FormFieldType;
  value: any;
  title?: string;
  dataType?: FormFieldTextType;
  hideExport?: boolean;
  fieldOptions?: FormOptions[];
  id: string;
  groupId?: string;
  saved?: boolean;
  dialog?: boolean;
  required?: boolean;
  preview?: boolean;
  style?: FormFieldStyle;
  onChange?: (value: any, error?: string | null, onLoad?: boolean) => void;
  disableGlobalOnChange?: boolean;
  icon?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  noDisabledStyle?: boolean;
  hideHeader?: boolean;
  noNoneOption?: boolean;
  onSave?: (object: any) => void;
  customGlobalSave?: boolean;
  objectProps?: any;
  clearOnSelect?: boolean;
  disableCloseOnSelect?: boolean;
  error?: boolean;
  helperText?: string;
}

const FormElement: FC<FormProps> = (props) => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const onSave = async (value: any) => {
    if (props.onSave) {
      props.onSave({
        id: props.id,
        value: value,
        ...props.objectProps,
      });
    } else {
      try {
        const res = await FormApi.updateFormField(
          context.clientId,
          context.authToken,
          {
            id: props.id,
            value: value,
          },
        );

        if (res.message) snackbarUtils.success(res.message);
      } catch (e) {
        snackbarUtils.error(context.i18n.error);
      }
      queryClient.invalidateQueries('formData');
      context.removeChangedCategoryData(props.id);
    }
  };

  const onCancel = () => {
    if (!props.disableGlobalOnChange)
      context.removeChangedCategoryData(props.id);
  };

  const onChange = (value: any, error: string | null, onLoad?: boolean) => {
    if (!props.disableGlobalOnChange) {
      context.updateChangedCategoryData({
        id: props.id,
        value: value,
        groupId: props.groupId,
        dialog: props.dialog,
        error: error,
        customGlobalSave: props.customGlobalSave,
        ...props.objectProps,
      });
    }
    props.onChange && props.onChange(value, error, onLoad);
  };

  switch (props.type) {
    case FormFieldType.TEXT:
      return (
        <FormText
          hideExport={props.hideExport}
          size={TextSize.NORMAL}
          value={props.value}
          dataType={props.dataType ? props.dataType : FormFieldTextType.TEXT}
          title={props.title}
          id={props.id}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
          icon={props.icon}
          disabled={props.disabled}
        />
      );
    case FormFieldType.TEXT_MEDIUM:
      return (
        <FormText
          hideExport={props.hideExport}
          size={TextSize.MEDIUM}
          value={props.value}
          dataType={props.dataType ? props.dataType : FormFieldTextType.TEXT}
          title={props.title}
          id={props.id}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.TEXT_LARGE:
      return (
        <FormText
          hideExport={props.hideExport}
          size={TextSize.LARGE}
          value={props.value}
          dataType={props.dataType ? props.dataType : FormFieldTextType.TEXT}
          title={props.title}
          id={props.id}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.WYSIWYG:
      return (
        <FormWysiwyg
          hideExport={props.hideExport}
          value={props.value}
          title={props.title}
          id={props.id}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
          noDisabledStyle={props.noDisabledStyle}
          error={props.error}
          helperText={props.helperText}
        />
      );
    case FormFieldType.RADIO:
      return (
        <FormRadio
          hideExport={props.hideExport}
          id={props.id}
          options={props.fieldOptions ? props.fieldOptions : []}
          title={props.title}
          value={props.value}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.DATE:
      return (
        <FormDate
          id={props.id}
          hideExport={props.hideExport}
          format={FormDateType.date}
          value={props.value}
          title={props.title}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.DATETIME:
      return (
        <FormDate
          id={props.id}
          hideExport={props.hideExport}
          format={FormDateType.datetime}
          value={props.value}
          title={props.title}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.DATE_NO_DAY:
      return (
        <FormDate
          id={props.id}
          hideExport={props.hideExport}
          format={FormDateType.month}
          value={props.value}
          title={props.title}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.DROPDOWN:
      return (
        <FormDropdown
          id={props.id}
          hideExport={props.hideExport}
          options={props.fieldOptions ? props.fieldOptions : []}
          title={props.title}
          value={props.value}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
          multiple={props.multiple}
          disabled={props.disabled}
          noNoneOption={props.noNoneOption}
          hideHeader={props.hideHeader}
          clearOnSelect={props.clearOnSelect}
        />
      );
    case FormFieldType.AUTOCOMPLETE:
      return (
        <FormAutocomplete
          disableCloseOnSelect={props.disableCloseOnSelect}
          id={props.id}
          hideExport={props.hideExport}
          options={props.fieldOptions ? props.fieldOptions : []}
          title={props.title}
          value={props.value || undefined}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
          multiple={props.multiple}
          disabled={props.disabled}
          noNoneOption={props.noNoneOption}
          hideHeader={props.hideHeader}
          clearOnSelect={props.clearOnSelect}
        />
      );
    case FormFieldType.CHECKBOX:
      return (
        <FormCheckBox
          id={props.id}
          hideExport={props.hideExport}
          options={props.fieldOptions ? props.fieldOptions : []}
          title={props.title}
          value={props.value}
          saved={props.saved}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          required={props.required}
          dialog={props.dialog}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.FORM_GROUP:
      return (
        <FormGroup
          id={props.id}
          hideExport={props.hideExport}
          title={props.title}
          values={props.value}
          required={props.required}
          preview={props.preview}
          onSave={props.onSave}
        />
      );
    case FormFieldType.LIST:
      return (
        <FormList
          id={props.id}
          hideExport={props.hideExport}
          title={props.title}
          values={props.value}
          required={props.required}
          preview={props.preview}
          style={props.style}
        />
      );
    case FormFieldType.LIST_MULTI_DATA:
      return (
        <FormListMultiData
          id={props.id}
          hideExport={props.hideExport}
          title={props.title}
          value={props.value}
          required={props.required}
          preview={props.preview}
          style={props.style}
        />
      );
  }

  return null;
};

export default FormElement;
