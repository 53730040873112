import {
  faPlus,
  faBars,
  faUsers,
  faGear,
  faFilePen,
  faArrowRightFromBracket,
  faUser,
  faPeopleRoof,
  faBoxArchive,
  faMoneyBill,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  useMediaQuery,
  Tooltip,
  Typography,
  Popover,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../hooks/context';
import { CategoryData } from '../../../types';
import { theme } from '../../../utils';
import {
  drawerWidthSpaced,
  hideOnSmallScreen,
  showOnlySmallScreen,
} from '../../../utils/constants';
import Header from '../header/header';
import Logo from '../logo/logo';
import SearchBar from '../search-bar/search-bar';
import SubCategorySelect from '../sub-category-select/sub-category-select';

interface NavbarProps {
  title: string;
  subTitle?: string;
  addClient?: boolean;
  search?: boolean;
  addFamily?: boolean;
  subCategories?: CategoryData[];
  onSelectCategory?: (id: string, subId: string) => void;
  onDrawerClicked?: () => void;
  open?: boolean;
  hideMenuDrawer?: boolean;
}

const defaultProps = {
  addClient: false,
  search: false,
};

const Navbar: FC<NavbarProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          {context.isAuthenticated && (
            <>
              {!props.hideMenuDrawer && (
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="menu"
                  disableRipple
                  sx={{ pl: 0 }}
                  onClick={(event) =>
                    props.onDrawerClicked && props.onDrawerClicked()
                  }
                >
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
              )}

              <Logo onClick={() => navigate('/clients')} />
            </>
          )}
          <Box
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {/* <Header title={props.title} subTitle={props.subTitle} /> */}
          </Box>
          <Tooltip title={context.i18n.appInfo}>
            <IconButton
              size="small"
              color="inherit"
              aria-label="menu"
              disableRipple
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }}>
            <b>Frontend:</b> {process.env.REACT_APP_VERSION}
            <br />
            <b>Backend:</b> {context.backendVersion}
          </Typography>
        </Popover>
      </AppBar>
      <Toolbar />
    </>
  );
};

Navbar.defaultProps = defaultProps;

export default Navbar;
