import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import { context } from 'msw';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { ClientApi, ICD10Api } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AppContext } from '../../../hooks/context';
import { ICD10Code } from '../../../types';
import ICD10FilterToolbar from './icd10-filter-toolbar';
import {
  faFileExport,
  faBoxOpen,
  faEye,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, IconButton } from '@mui/material';
import { queryClient } from '../../../utils';
import { ICD10DetailsDialog } from '../../dialogs';

interface ICD10TableProps {}

const ICD10Table: FC<ICD10TableProps> = (props) => {
  const context = useContext(AppContext);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);

  const [rowCount, setRowCount] = useState(0);

  const [detailsCode, setDetailsCode] = useState<ICD10Code>();

  const { isLoading } = useQuery(
    ['icd10Codes', pageSize, pageNum, sortModel, searchText],
    () => {
      const data: any = Object.assign(
        {
          pageNum,
          pageSize,
          sortModel,
          clientId: context.clientId,
        },
        searchText !== '' && {
          searchText,
        },
      );

      return ICD10Api.listICD10Codes(context.authToken, data);
    },
    {
      enabled:
        context.authToken !== undefined && context.clientId !== undefined,
      onSuccess: (res) => {
        setRows(
          res.data.codes.map((code: ICD10Code) => {
            return { ...code, id: code.code };
          }),
        );
        setRowCount(res.data.totalCount);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const tableColumns: Array<GridColDef> = [
    {
      field: 'code',
      headerName: context.i18n.code,
      minWidth: 150,
      flex: 0.5,
      hideable: false,
    },
    {
      field: 'display',
      headerName: context.i18n.display,
      hideable: true,
      flex: 1.5,
    },
    // {
    //   field: 'defenition',
    //   headerName: context.i18n.defenition,
    //   flex: 1,
    //   hideable: true,
    // },
    // {
    //   field: 'exclusions',
    //   headerName: context.i18n.exclusions,
    //   flex: 1,
    //   hideable: true,
    //   sortable: false,
    // },
    // {
    //   field: 'inclusions',
    //   headerName: context.i18n.inclusions,
    //   flex: 1,
    //   hideable: true,
    //   sortable: false,
    // },
    {
      field: 'actions',
      headerName: '',
      flex: 0.5,
      hideable: false,
      renderCell: (params: any) => (
        <>
          <Tooltip
            title={context.i18n.formatString(
              context.i18n.openCodeDetails,
              params.row.id,
            )}
          >
            <IconButton
              disableRipple
              color="primary"
              id={'detail-button-' + params.id}
              sx={{ height: 39 }}
              disabled={!context.basicActive}
              onClick={() => setDetailsCode(params.row)}
            >
              <FontAwesomeIcon icon={faEye} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={context.i18n.formatString(
              context.i18n.addDiagnosis,
              params.row.id,
            )}
          >
            <IconButton
              disableRipple
              color="success"
              id={'add-button-' + params.id}
              sx={{ height: 39 }}
              disabled={!context.basicActive}
              onClick={() => onAddCode(params.id)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const onAddCode = async (code: string) => {
    const res = await ICD10Api.addICD10Code(
      context.authToken,
      context.clientId,
      code,
    );

    if (res.statusCode === 201) {
      if (res.message) {
        snackbarUtils.success(res.message);
      }
      queryClient.invalidateQueries('icd10Codes');
    } else {
      snackbarUtils.error(context.i18n.addCodeError);
    }
  };

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
        }}
        slotProps={{
          toolbar: {
            searchText,
            setSearchText,
          },
        }}
        slots={{
          toolbar(props: any) {
            return ICD10FilterToolbar(props);
          },
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        columnBufferPx={4}
        // Pagination settings
        paginationMode="server"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      {detailsCode && (
        <ICD10DetailsDialog
          open={detailsCode !== undefined}
          onClose={() => setDetailsCode(undefined)}
          code={detailsCode}
        />
      )}
    </>
  );
};

export default ICD10Table;
