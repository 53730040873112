import { Tabs, Tab, Typography, Box } from '@mui/material';
import { FC, useContext } from 'react';
import { TabStyle } from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { GenoGrammLayerTypeOrder } from '@casecare/types';

interface GenoGrammLayerTabsProps {
  selectedLayer: GenoGrammLayerTypeOrder;
  onSelectLayer: (layer: GenoGrammLayerTypeOrder) => void;
}

const GenoGrammLayerTabs: FC<GenoGrammLayerTabsProps> = (props) => {
  const context = useContext(AppContext);
  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        sx={{
          m: 0,
          height: 58,
          alignItems: 'end',
          overflow: 'auto',
        }}
        scrollButtons={false}
        variant="scrollable"
        value={props.selectedLayer}
      >
        {Object.keys(GenoGrammLayerTypeOrder)
          .filter((v) => !isNaN(Number(v)))
          .map((key, value: GenoGrammLayerTypeOrder) => (
            <Tab
              key={value}
              sx={TabStyle}
              value={value}
              onClick={() => {
                props.onSelectLayer(value);
              }}
              label={
                <Typography color="white" textTransform="none">
                  {context.i18n[`layer_${GenoGrammLayerTypeOrder[value]}`]}
                </Typography>
              }
            />
          ))}
      </Tabs>
      <Box
        sx={{
          p: 1,
          bgcolor: 'primary.main',
          overflowY: 'auto',
        }}
      />
    </>
  );
};

export default GenoGrammLayerTabs;
