import { Badge, BadgeProps } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import ghost from '../../../assets/avatar/ghost.svg';

interface AvatarDeadIconProps extends BadgeProps {}

const AvatarDeadIcon: FC<AvatarDeadIconProps> = (props) => {
  console.log(props);
  return (
    <Badge
      {...props}
      sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
      badgeContent={<img style={{ width: '100%' }} src={ghost} />}
    >
      {props.children}
    </Badge>
  );
};

export default AvatarDeadIcon;
