import { GenoGrammLayerTypeOrder } from '@casecare/types';
import { Grid, Box, Drawer } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { GenoGrammApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import GenoGrammAddPerson from '../geno-gramm-add-person/geno-gramm-add-person';
import GenoGrammClient from '../geno-gramm-client/geno-gramm-client';
import GenoGrammLayerTabs from '../geno-gramm-layer-tabs/geno-gramm-layer-tabs';

interface GenoGrammDrawerPersonProps {
  open: boolean;
  onClose: () => void;
  indexClient: any;
  clients: any[];
  selected: string | undefined;
  setSelected: (selected: any) => void;
}

const GenoGrammDrawerPerson: FC<GenoGrammDrawerPersonProps> = (props) => {
  const context = useContext(AppContext);

  const [clients, setClients] = useState<any[]>(props.clients);
  const [clientsFiltered, setClientsFiltered] = useState<any[]>([]);
  const [indexClient, setIndexClient] = useState<any>(props.indexClient);
  const [selectedLayer, setSelectedLayer] = useState<GenoGrammLayerTypeOrder>(
    GenoGrammLayerTypeOrder.INDEX,
  );

  useEffect(() => {
    setIndexClient(props.indexClient);
  }, [props.indexClient]);

  useEffect(() => {
    setClients(props.clients);
  }, [props.clients]);

  useEffect(() => {
    const filtered = clients.filter((client) => client.layer == selectedLayer);
    setClientsFiltered(filtered);
  }, [clients, selectedLayer]);

  const onSaveClient = async (client: any) => {
    try {
      const groupIdClient = 'Geno-Gramm-Client-Data-' + client.id,
        groupIdClientRelation = 'Geno-Gramm-Client-Relations-' + client.id;

      const groupErrors = context.getGroupDataError([
        groupIdClient,
        groupIdClientRelation,
      ]);

      const error = groupErrors.length > 0;

      if (!error) {
        // SAVE relations //
        let i = 0;
        if (client.genoGrammRelations)
          for (const data of client.genoGrammRelations) {
            if (data.relationId !== props.indexClient.id) {
              if (!data.delete) {
                await GenoGrammApi.editRelation(
                  context.authToken,
                  props.indexClient.id,
                  {
                    type: data.type,
                    clientFromId: data.clientId,
                    clientToId: data.relationId,
                  },
                );
              } else {
                await GenoGrammApi.deleteRelation(
                  context.authToken,
                  props.indexClient.id,
                  {
                    clientFromId: data.clientId,
                    clientToId: data.relationId,
                  },
                );
                client.genoGrammRelations.splice(i, 1);
              }
              i++;
            }
          }

        // SAVE client data //
        const groupData = context.getAndRemoveGroupData(groupIdClient);
        const sendData: Record<string, any> = {};
        for (const data of groupData) {
          if (data.value !== '') {
            sendData[data.id] = data.value;
          }
        }

        sendData.id = client.id;

        const res = await GenoGrammApi.saveClient(
          context.authToken,
          props.indexClient.id,
          sendData,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          queryClient.invalidateQueries('geno-gramm-data');
        }
      } else snackbarUtils.error(context.i18n.redFieldsNeedToBeFilledOut);

      context.removeGroupData(groupIdClientRelation);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Drawer
      open={props.open}
      PaperProps={{ sx: { width: '33%' } }}
      onClose={() => props.onClose()}
    >
      <Grid height="100%" container spacing={4}>
        <Grid item xs={12}>
          <GenoGrammAddPerson
            indexClient={indexClient}
            selected={props.selected}
            setSelected={props.setSelected}
          />
          <Box sx={{ mt: 5 }}>
            <GenoGrammLayerTabs
              onSelectLayer={setSelectedLayer}
              selectedLayer={selectedLayer}
            />
            {clientsFiltered.map((c) => (
              <GenoGrammClient
                key={c.id}
                client={c}
                indexClient={indexClient}
                clients={clients}
                onSaveClient={onSaveClient}
                selected={props.selected}
                setSelected={props.setSelected}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default GenoGrammDrawerPerson;
