import { faCaretDown, faX, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tabs,
  Tab,
  Grid,
  Button,
  Paper,
  Box,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { queryClient, theme } from '../../../utils';
import { AccordionStyle } from '../../../utils/styling/styling';
import { AppContext } from '../../../hooks/context';
import { ClientApi, GenoGrammApi } from '../../../api';
import { ClientDataType } from '@casecare/types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { GenoGrammClientData, GenoGrammRelation } from '..';

interface GenoGrammClientProps {
  client: any;
  indexClient: any;
  clients: any[];
  onSaveClient: (client: any) => void;
  selected: string | undefined;
  setSelected: (selected: any) => void;
}

const GenoGrammClient: FC<GenoGrammClientProps> = (props) => {
  const context = useContext(AppContext);

  const [client, setClient] = useState(props.client);

  useEffect(() => {
    setClient(props.client);
  }, [props.client]);

  // const onRemoveClient = async (id: string) => {
  //   try {
  //     const res = await ClientApi.updateClientArchiveState(
  //       context.authToken,
  //       id,
  //       true,
  //     );

  //     if (res.message) {
  //       snackbarUtils.success(res.message);
  //     }

  //     queryClient.invalidateQueries(['geno-gramm-data']);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  return (
    <Accordion
      expanded={props.selected === client.id}
      square
      disableGutters
      elevation={0}
      slotProps={{ transition: { unmountOnExit: true } }}
      sx={{
        ...AccordionStyle(undefined, theme.palette.primary.main),
      }}
    >
      <AccordionSummary
        onClick={() => props.setSelected(client.id)}
        expandIcon={
          <FontAwesomeIcon
            size="2x"
            color={theme.palette.primary.main}
            icon={faCaretDown}
          />
        }
      >
        <Typography sx={{ ml: 2 }}>
          {client[ClientDataType.FIRST_NAME]} {client[ClientDataType.LAST_NAME]}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 'unset !important' }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            {client.id !== props.indexClient.id && (
              <GenoGrammRelation
                client={client}
                indexId={props.indexClient.id}
                clients={props.clients}
                setClient={setClient}
              />
            )}
            <GenoGrammClientData
              isIndex={client.id === props.indexClient.id}
              client={client}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Button
              variant="contained"
              sx={{ p: 2, width: '100%' }}
              color="inherit"
              onClick={() => onRemoveClient(client.id)}
              endIcon={
                <FontAwesomeIcon color={theme.palette.error.main} icon={faX} />
              }
            >
              {context.i18n.removePerson}
            </Button> */}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={{ p: 2, width: '100%' }}
              color="inherit"
              size="large"
              endIcon={
                <FontAwesomeIcon
                  color={theme.palette.success.main}
                  icon={faCheck}
                />
              }
              onClick={() => props.onSaveClient(client)}
            >
              {context.i18n.save}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default GenoGrammClient;
