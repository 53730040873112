import { FC } from 'react';
import { ReactComponent as Outlined } from '../../assets/icons/Stern/Stern Umrandung.svg';
import { ReactComponent as Filled } from '../../assets/icons/Stern/Stern gefuellt.svg';
import { ReactComponent as Border } from '../../assets/icons/Stern/Stern Rahmen.svg';
import CaseCareSvgIcon from './case-care-svg-icon';
import { CaseCareSvgIconProps } from '../../types/case-care-svg-icon';

const StarIcon: FC<CaseCareSvgIconProps> = (props) => {
  return (
    <CaseCareSvgIcon
      {...props}
      default={<Outlined />}
      outlined={<Outlined />}
      filled={<Filled />}
      border={<Border />}
    />
  );
};
export default StarIcon;
