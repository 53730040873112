import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { AppContext } from '../../../hooks/context';
import { useQueryClient } from 'react-query';
import { AdminApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { DateTimePicker, DateTimeRangePicker } from '@mui/x-date-pickers-pro';

interface EditOrgMessageDialogProps {
  open: boolean;
  onClose: () => void;
  orgMessage: any;
}

const EditOrgMessageDialog: FC<EditOrgMessageDialogProps> = (
  props: EditOrgMessageDialogProps,
) => {
  const { open, onClose, orgMessage } = props;
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(
    null,
  );
  const [displayUsers, setDisplayUsers] = useState<string[]>([]);
  const [displayRoles, setDisplayRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (orgMessage) {
      setSubject(orgMessage.subject || '');
      setMessage(orgMessage.message || '');
      setExpirationDate(
        orgMessage.expirationDate ? moment(orgMessage.expirationDate) : null,
      );
    }
  }, [orgMessage]);

  const resetState = () => {
    setSubject('');
    setMessage('');
    setHasExpirationDate(false);
    setExpirationDate(null);
    setDisplayUsers([]);
    setDisplayRoles([]);
  };

  useEffect(() => {
    if (!hasExpirationDate && expirationDate) {
      setExpirationDate(null);
    }
  }, [hasExpirationDate, expirationDate]);

  const editMessage = async () => {
    setLoading(true);
    await AdminApi.editOrgMessage(
      context.authToken,
      {
        subject,
        message,
        expirationDate: expirationDate ? expirationDate.toISOString() : null,
      },
      orgMessage.id,
    )
      .then((res) => {
        snackbarUtils.success(res.message || context.i18n.success);
        closeAndReload();
      })
      .finally(() => setLoading(false));
  };

  const closeAndReload = () => {
    resetState();
    queryClient.invalidateQueries('listOrgMessages');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
    >
      <DialogTitle>{context.i18n.createOrgMessage}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <TextField
            color="secondary"
            label={context.i18n.subject}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            value={subject}
            onChange={(e) => setSubject(e.currentTarget.value)}
            inputProps={{
              sx: { backgroundColor: 'white' },
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <TextField
            color="secondary"
            label={context.i18n.message}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            inputProps={{
              sx: { backgroundColor: 'white' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') console.log('Enter');
            }}
            minRows={3}
            multiline
          />
        </Box>
        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox
              checked={hasExpirationDate}
              onClick={() => setHasExpirationDate(!hasExpirationDate)}
            />
          }
          label={context.i18n.hasExpirationDate}
        />
        {hasExpirationDate && (
          <Box display="flex" justifyContent="center">
            <DateTimePicker
              format="DD.MM.YYYY HH:mm"
              minDateTime={moment()}
              value={expirationDate}
              sx={{ width: '22rem' }}
              onChange={(date) => setExpirationDate(date)}
              timeSteps={{ minutes: 15 }}
              minutesStep={15}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              localeText={{
                start: context.i18n.startDate,
                end: context.i18n.endDate,
                startDate: context.i18n.startDate,
                endDate: context.i18n.endDate,
                startTime: context.i18n.startTime,
                endTime: context.i18n.endTime,
              }}
            />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="createBtn"
            onClick={editMessage}
            className="formBtn"
            disabled={subject === '' || message === ''}
            sx={{ color: 'white', mr: 'auto', ml: 'auto' }}
          >
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              context.i18n.editOrgMessage
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditOrgMessageDialog;
