import {
  Autocomplete,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { drawerWidthConst } from '../../../utils/constants';
import {
  faArrowRightFromBracket,
  faBoxArchive,
  faCaretDown,
  faCaretUp,
  faContactBook,
  faDollar,
  faEnvelope,
  faFile,
  faFileCircleCheck,
  faFileCircleMinus,
  faFileCircleQuestion,
  faFileInvoice,
  faFilePen,
  faFolder,
  faGear,
  faHandshake,
  faInstitution,
  faMagnifyingGlass,
  faMoneyBill,
  faPlus,
  faTasks,
  faTasksAlt,
  faUser,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../hooks/context';
import SearchBar from '../search-bar/search-bar';
import { CategoryData } from '../../../types';
import { theme } from '../../../utils';
import { CreateClientDialog, AddFamilyDialog } from '../../dialogs';
import { OrgApi } from '../../../api';
import { useQuery } from 'react-query';

interface MenuDrawerProps {
  selected?: string;
  admin?: boolean;
  storage?: boolean;
  billing?: boolean;
  adminBilling?: boolean;
  showCategories?: boolean;
  categories?: CategoryData[];
  storages?: any[];
  open: boolean;
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
}

const MenuDrawer: FC<MenuDrawerProps> = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(props.open);
  const [expandAdmin, setExpandAdmin] = useState(props.admin);
  const [expandCategoryId, setExpandCategoryId] = useState<string>();
  const [showCreateFamily, setShowCreateFamily] = useState(false);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [focusSearch, setFocusSearch] = useState(false);
  const [expandBilling, setExpandBilling] = useState(props.billing);
  const [expandAdminBilling, setExpandAdminBilling] = useState(
    context.sevDeskActive ? props.adminBilling : false,
  );
  const [imageUrl, setImageUrl] = useState<string>();

  const [expandStorage, setExpandStorage] = useState(props.storage);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const ListButtonStyle = {
    height: '4rem',
    justifyContent: open ? 'initial' : 'center',
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
    },
    '& .MuiListItemText-root': {
      opacity: open ? 1 : 0,
    },
    '& .parent': {
      ml: 3,
    },
  };

  useQuery(['logo'], () => OrgApi.getLogo(context.authToken), {
    enabled: context.authToken !== undefined,
    onSuccess: (res) => {
      setImageUrl(res.data);
    },
  });

  useEffect(() => {
    if (!props.open) {
      setExpandAdmin(false);
      setExpandBilling(false);
    }
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setExpandAdmin(props.admin);
  }, [props.admin]);

  return (
    <Drawer
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      open={open}
      anchor="left"
      sx={{
        flexShrink: 0,
        '&& .Mui-selected': {
          // .replace() used to add opacity to color
          background: theme.palette.secondary.light.replace(')', ', 0.1)'),
          color: theme.palette.secondary.light,
          '& .MuiListItemIcon-root': {
            color: theme.palette.secondary.light,
          },
        },
        '& .MuiListItemButton-root:hover': {
          // .replace() used to add opacity to color
          background: theme.palette.secondary.light.replace(')', ', 0.1)'),
        },
        '& .MuiDrawer-paper': {
          zIndex: 1099,
          width: open ? drawerWidthConst : `calc(${theme.spacing(9)} + 1px)`,
          overflowX: open ? 'inherit' : 'hidden',
          background: theme.palette.primary.main,
          msOverflowStyle: 'none',
          boxSizing: 'border-box',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          '&::-webkit-scrollbar': {
            width: '0.4em', // Set the width of the scrollbar to zero
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent', // Make the thumb transparent
          },
        },
        '& .MuiListItem-root': {
          color: theme.palette.secondary.main,
          '& .MuiListItemIcon-root': {
            color: theme.palette.secondary.main,
          },
        },
      }}
    >
      <Toolbar sx={{ pb: 3 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img style={{ maxHeight: 50, maxWidth: 100 }} src={imageUrl} />
      </Box>

      {/* <Box
        width="100%"
        height={50}
        display="flex"
        justifyContent="center"
        mt={2}
        mb={2}
      >
        <Logo />
      </Box> */}
      <ClickAwayListener
        onClickAway={() => (isSmallScreen ? props.onCloseDrawer() : {})}
      >
        <List color="secondary">
          <ListItem disablePadding={!open}>
            {open ? (
              <Button
                sx={{ maxHeight: 40 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => setShowCreateClient(true)}
                disabled={!context.basicActive}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                {context.i18n.addClient}
              </Button>
            ) : (
              <Tooltip title={context.i18n.addClient}>
                <ListItemButton
                  className="parent"
                  sx={ListButtonStyle}
                  onClick={() => setShowCreateClient(true)}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUserPlus} />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            )}
          </ListItem>
          {/* <ListItem disablePadding={!open}>
            {open ? (
              <Button
                sx={{ maxHeight: 40 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => setShowCreateFamily(true)}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                disabled={!context.basicActive || !context.familyActive}
              >
                {context.i18n.addFamily}
              </Button>
            ) : (
              <Tooltip title={context.i18n.addFamily}>
                <ListItemButton
                  sx={ListButtonStyle}
                  onClick={() => setShowCreateFamily(true)}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faPlus} />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            )}
          </ListItem> */}
          <ListItem sx={{ mb: open ? 1 : 0 }} disablePadding={!open}>
            {open ? (
              <SearchBar focus={focusSearch} />
            ) : (
              <Tooltip title={context.i18n.clientSearch}>
                <ListItemButton
                  sx={ListButtonStyle}
                  onClick={() => {
                    props.onOpenDrawer();
                    setFocusSearch(true);
                    setTimeout(() => {
                      setFocusSearch(false);
                    }, 100);
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            )}
          </ListItem>
          <ListItem disablePadding>
            <Tooltip
              sx={{
                '& .MuiTooltip-popper': {
                  mt: '-8px',
                },
              }}
              title={context.i18n.siteTitleTodo}
            >
              <ListItemButton
                sx={ListButtonStyle}
                selected={props.selected === 'todo'}
                onClick={() => navigate('/todo')}
              >
                <ListItemIcon>
                  <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        fontSize: 9,
                        height: 10,
                        minWidth: 10,
                      },
                    }}
                    badgeContent={context.newTodos}
                    color="error"
                  >
                    <FontAwesomeIcon icon={faTasks} />
                  </Badge>
                </ListItemIcon>

                <ListItemText>{context.i18n.siteTitleTodo}</ListItemText>
              </ListItemButton>
            </Tooltip>
          </ListItem>
          {(context.isAdmin || context.isTeamLead) && (
            <ListItem disablePadding>
              <Tooltip
                sx={{
                  '& .MuiTooltip-popper': {
                    mt: '-8px',
                  },
                }}
                title={context.i18n.siteTitleTodoteam}
              >
                <ListItemButton
                  sx={ListButtonStyle}
                  selected={props.selected === 'todo-team'}
                  onClick={() => navigate('/todo-team')}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTasksAlt} />
                  </ListItemIcon>

                  <ListItemText>{context.i18n.siteTitleTodoteam}</ListItemText>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
          <ListItem disablePadding>
            <Tooltip title={context.i18n.siteTitleClients}>
              <ListItemButton
                sx={ListButtonStyle}
                selected={props.selected === 'clients'}
                onClick={() => navigate('/clients')}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUsers} />
                </ListItemIcon>

                <ListItemText>{context.i18n.siteTitleClients}</ListItemText>
              </ListItemButton>
            </Tooltip>
          </ListItem>
          {/* {context.familyActive && (
            <ListItem disablePadding>
              <Tooltip title={context.i18n.familyOverview}>
                <ListItemButton
                  sx={ListButtonStyle}
                  onClick={() => navigate('/family')}
                  selected={props.selected === 'family'}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faPeopleRoof} />
                  </ListItemIcon>
                  <ListItemText>{context.i18n.familyOverview}</ListItemText>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )} */}
          {(context.isAdmin || context.isControlling) && context.billingActive && (
            <ListItem disablePadding>
              <Tooltip title={context.i18n.siteTitleBilling}>
                <ListItemButton
                  sx={ListButtonStyle}
                  onClick={() => {
                    setExpandBilling(!expandBilling);
                    props.onOpenDrawer();
                  }}
                >
                  <ListItemIcon>
                    <Badge
                      sx={{
                        '& .MuiBadge-badge': {
                          fontSize: 9,
                          height: 10,
                          minWidth: 10,
                        },
                      }}
                      badgeContent={context.newProtocols}
                      color="error"
                    >
                      <FontAwesomeIcon icon={faFileInvoice} />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>{context.i18n.siteTitleBilling}</ListItemText>
                  <Box sx={{ display: open ? 'inherit' : 'none' }}>
                    {expandBilling ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </Box>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
          {(context.isControlling || context.isAdmin) && (
            <Collapse
              sx={{ pl: 2 }}
              in={expandBilling && props.open}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding sx={{ pl: 2 }}>
                <ListItem disablePadding>
                  <Tooltip title={context.i18n.siteTitleBillingReview}>
                    <ListItemButton
                      selected={props.selected === 'billing-review'}
                      onClick={() => navigate('/billing/review')}
                    >
                      <ListItemIcon>
                        <Badge
                          sx={{
                            '& .MuiBadge-badge': {
                              fontSize: 9,
                              height: 10,
                              minWidth: 10,
                            },
                          }}
                          badgeContent={context.newProtocols}
                          color="error"
                        >
                          <FontAwesomeIcon icon={faFileCircleQuestion} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText>
                        {context.i18n.siteTitleBillingReview}
                      </ListItemText>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem disablePadding>
                  <Tooltip title={context.i18n.siteTitleBillingReleased}>
                    <ListItemButton
                      selected={props.selected === 'billing-released'}
                      onClick={() => navigate('/billing/released')}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faFileCircleCheck} />
                      </ListItemIcon>
                      <ListItemText>
                        {context.i18n.siteTitleBillingReleased}
                      </ListItemText>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem disablePadding>
                  <Tooltip title={context.i18n.siteTitleBillingRejected}>
                    <ListItemButton
                      selected={props.selected === 'billing-rejected'}
                      onClick={() => navigate('/billing/rejected')}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faFileCircleMinus} />
                      </ListItemIcon>
                      <ListItemText>
                        {context.i18n.siteTitleBillingRejected}
                      </ListItemText>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </List>
            </Collapse>
          )}
          {context.isAdmin && (
            <ListItem disablePadding>
              <Tooltip title={context.i18n.adminArea}>
                <ListItemButton
                  sx={ListButtonStyle}
                  onClick={() => {
                    setExpandAdmin(!expandAdmin);
                    props.onOpenDrawer();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faGear} />
                  </ListItemIcon>
                  <ListItemText>{context.i18n.adminArea}</ListItemText>
                  <Box sx={{ display: open ? 'inherit' : 'none' }}>
                    {expandAdmin ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </Box>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
          {context.isAdmin && (
            <Collapse
              sx={{ pl: 2 }}
              in={expandAdmin && props.open}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding sx={{ pl: 2 }}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-users'}
                    onClick={() => navigate('/admin/users')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUser} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.userManagement}</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-groups'}
                    onClick={() => navigate('/admin/groups')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUsers} />
                    </ListItemIcon>
                    <ListItemText>
                      {context.i18n.userGroupmanagement}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-org-messages'}
                    onClick={() => navigate('/admin/organization-messages')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.orgMessages}</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-help-form'}
                    onClick={() => navigate('/admin/help-form')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faHandshake} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.helpForm}</ListItemText>
                    <Box sx={{ display: open ? 'inherit' : 'none' }}>
                      {window.location.pathname.includes('/admin/help-form') ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </Box>
                  </ListItemButton>
                </ListItem>
                <Collapse
                  sx={{ pl: 2 }}
                  in={window.location.pathname.includes('/admin/help-form')}
                >
                  <List disablePadding sx={{ pl: 2 }}>
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={props.selected === 'admin-help-form-orderer'}
                        onClick={() => navigate('/admin/help-form/orderer')}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faInstitution} />
                        </ListItemIcon>
                        <ListItemText>{context.i18n.orderer}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-archive'}
                    onClick={() => navigate('/admin/archive')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faBoxArchive} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.clientArchive}</ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-billing'}
                    onClick={() => {
                      navigate('/admin/billing');
                      if (context.sevDeskActive) {
                        setExpandAdminBilling(!expandAdminBilling);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faMoneyBill} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.billingDetails}</ListItemText>
                    {context.sevDeskActive && (
                      <Box sx={{ display: open ? 'inherit' : 'none' }}>
                        {expandAdminBilling ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  sx={{ pl: 2 }}
                  in={expandAdminBilling && props.open}
                  unmountOnExit
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={props.selected === 'admin-billing-contacts'}
                      onClick={() => navigate('/admin/billing/contacts')}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faContactBook} />
                      </ListItemIcon>
                      <ListItemText>{context.i18n.contacts}</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={props.selected === 'admin-billing-products'}
                      onClick={() => navigate('/admin/billing/products')}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faDollar} />
                      </ListItemIcon>
                      <ListItemText>{context.i18n.products}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Collapse>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-categories'}
                    onClick={() => navigate('/admin/categories')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faFilePen} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.editCategories}</ListItemText>
                  </ListItemButton>
                </ListItem>
                {props.showCategories &&
                  props.categories?.map((c) => (
                    <Collapse
                      sx={{ pl: 2 }}
                      key={c.id}
                      in={props.showCategories && props.open}
                    >
                      <List disablePadding sx={{ pl: 2 }}>
                        <ListItem disablePadding>
                          {c.childCategories &&
                          c.childCategories?.length > 0 ? (
                            <ListItemButton
                              onClick={() => {
                                setExpandCategoryId(c.id);
                              }}
                            >
                              <ListItemText>{c.label}</ListItemText>
                              {expandCategoryId === c.id ? (
                                <FontAwesomeIcon icon={faCaretUp} />
                              ) : (
                                <FontAwesomeIcon icon={faCaretDown} />
                              )}
                            </ListItemButton>
                          ) : (
                            <ListItemButton selected={props.selected === c.id}>
                              <ListItemText>{c.label}</ListItemText>
                            </ListItemButton>
                          )}
                        </ListItem>
                        <Collapse
                          in={
                            expandCategoryId === c.id ||
                            (c.childCategories?.find(
                              (c) => c.id === props.selected,
                            ) !== undefined &&
                              props.open)
                          }
                        >
                          <List disablePadding sx={{ pl: 2 }}>
                            {props.open &&
                              c.childCategories?.map((cSub) => (
                                <ListItem key={cSub.id} disablePadding>
                                  <ListItemButton
                                    selected={props.selected === cSub.id}
                                    onClick={() =>
                                      navigate(
                                        `/admin/categories/${c.id}/${cSub.id}`,
                                      )
                                    }
                                  >
                                    <ListItemText>{cSub.label}</ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </List>
                    </Collapse>
                  ))}
                {/* <Collapse
                  in={
                    props.categories?.find(
                      (c) => c.id === props.selected,
                    ) !== undefined
                  }
                >
                  <List disablePadding sx={{ pl: 2 }}>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => setExpandCategories(!expandCategories)}
                        selected={props.selected === 'list'}
                      >
                        <ListItemText>{props.categoryName}</ListItemText>
                        {expandCategories ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={expandCategories}>
                      <List disablePadding sx={{ pl: 2 }}>
                        {props.open &&
                          props.categories?.map((c) => (
                            <ListItem key={c.id} disablePadding>
                              <ListItemButton
                                selected={props.selected === c.id}
                                onClick={() =>
                                  props.onSelectCategory &&
                                  props.onSelectCategory(c.id)
                                }
                              >
                                <ListItemText>{c.label}</ListItemText>
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </Collapse>
                  </List>
                </Collapse> */}
                {/* <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'abo'}
                    onClick={() => navigate('/admin/abo')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faMoneyBill} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.abos}</ListItemText>
                  </ListItemButton>
                </ListItem>
            */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={props.selected === 'admin-data'}
                    onClick={() => navigate('/admin/data')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faGear} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.orgData}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          )}
          {props.storages && props.storages.length > 0 && (
            <>
              <ListItem disablePadding>
                <Tooltip title={context.i18n.storage}>
                  <ListItemButton
                    sx={ListButtonStyle}
                    onClick={() => {
                      setExpandStorage(!expandStorage);
                      props.onOpenDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faFile} />
                    </ListItemIcon>
                    <ListItemText>{context.i18n.storage}</ListItemText>
                    <Box sx={{ display: open ? 'inherit' : 'none' }}>
                      {expandAdmin ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </Box>
                  </ListItemButton>
                </Tooltip>
              </ListItem>

              <Collapse
                sx={{ pl: 2 }}
                in={expandStorage && props.open}
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding sx={{ pl: 2 }}>
                  {props.storages.map((storage: any) => (
                    <ListItem disablePadding key={storage.id}>
                      <ListItemButton
                        selected={props.selected === storage.id}
                        onClick={() => navigate('/storage/' + storage.id)}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faFolder} />
                        </ListItemIcon>
                        <ListItemText>{storage.name}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          <ListItem disablePadding>
            <Tooltip title={context.i18n.logout}>
              <ListItemButton
                sx={ListButtonStyle}
                onClick={() => {
                  props.onCloseDrawer();
                  context.logout();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </ListItemIcon>
                <ListItemText>{context.i18n.logout}</ListItemText>
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </ClickAwayListener>
      <CreateClientDialog
        open={showCreateClient}
        onClose={() => setShowCreateClient(false)}
        onFinish={(id, isFamily) => {
          isFamily
            ? navigate('/families/' + id + '/familyData')
            : navigate('/clients/' + id + '/clientData');
        }}
      />
      <AddFamilyDialog
        open={showCreateFamily}
        onClose={() => setShowCreateFamily(false)}
      />
    </Drawer>
  );
};

export default MenuDrawer;
