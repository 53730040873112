import { ClientDataType } from '@casecare/types';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Typography,
  Box,
  Tabs,
  IconButton,
  Dialog,
  Drawer,
  ClickAwayListener,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { GenoGrammApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { useSavePrompt } from '../../../hooks/useBlocker';
import {
  setDocumentTitle,
  loadTitle,
  queryClient,
  theme,
} from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { TabScrollButtonEcoMapStyle } from '../../../utils/styling/styling';
import GenderIcon from '../../core/gender-icon/gender-icon';
import { DiscardDialog } from '../../dialogs';
import GenoGrammAddPerson from '../geno-gramm-add-person/geno-gramm-add-person';
import GenoGrammLayerTabs from '../geno-gramm-layer-tabs/geno-gramm-layer-tabs';
import { GenoGrammAddPregnancy } from '../geno-gramm-add-pregnancy/geno-gramm-add-pregnancy';
import { Console } from 'console';
import { GenoGrammPregnancies } from '../geno-gramm-pregnancies/geno-gramm-pregnancies';

interface GenoGrammDrawerPregnancyProps {
  open: boolean;
  onClose: () => void;
  client: any;
  pregnancies: any;
  clientList: any;
  indexId: string | undefined;
}

const GenoGrammDrawerPregnancy: FC<GenoGrammDrawerPregnancyProps> = (props) => {
  const context = useContext(AppContext);

  const [pregnancies, setPregnancies] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState<number | string>(0);
  const [futureLayer, setFutureLayer] = useState<number | string>(0);
  const [changes, setChanges] = useState(false);
  const [openSavePrompt, setOpenSavePrompt] = useState(false);

  useEffect(() => {
    setPregnancies(props.pregnancies);
  }, [props.pregnancies]);

  useEffect(() => {
    if (changes && futureLayer !== selectedLayer) {
      setOpenSavePrompt(true);
    } else {
      setSelectedLayer(futureLayer);
    }
  }, [futureLayer]);

  return (
    <Drawer
      open={props.open}
      PaperProps={{ sx: { width: '50%' } }}
      onClose={(ev, reason) => props.onClose()}
    >
      <Grid height="100%" container spacing={4}>
        <Grid item xs={12}>
          <GenoGrammAddPregnancy
            indexId={props.indexId}
            clientList={props.clientList}
          />
          <Box sx={{ mt: 5 }}>
            <GenoGrammPregnancies
              indexId={props.indexId}
              clientList={props.clientList}
              pregnancies={pregnancies}
            />
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default GenoGrammDrawerPregnancy;
