import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  faCaretDown,
  faPersonCirclePlus,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  colors,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  Grid,
  Button,
} from '@mui/material';
import client from '../../../api/client/client';
import { queryClient, theme } from '../../../utils';
import { AccordionStyle } from '../../../utils/styling/styling';
import GenoGrammClientData from '../geno-gramm-client-data/geno-gramm-client-data';
import { ClientDataType, Sex } from '@casecare/types';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { GenoGrammApi } from '../../../api';

interface GenoGrammAddPersonProps {
  indexClient: any;
  selected: string | undefined;
  setSelected: (selected: any) => void;
}

const GenoGrammAddPerson: FC<GenoGrammAddPersonProps> = (props) => {
  const context = useContext(AppContext);

  const [client, setClient] = useState({
    [ClientDataType.FIRST_NAME]: '',
    [ClientDataType.LAST_NAME]: '',
    [ClientDataType.SEX]: Sex.MALE,
  });
  const [isLoading, setIsLoading] = useState(false);

  const colors = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  };

  const groupId = 'Geno-Gramm-Client-Data-CREATE';

  const onSaveClient = async () => {
    try {
      const groupErrors = context.getGroupDataError(groupId),
        error = groupErrors.length > 0;

      if (!error) {
        setIsLoading(true);

        const groupData = context.getAndRemoveGroupData(groupId);

        const sendData: Record<string, any> = {};
        for (const data of groupData) {
          if (data.value && data.value !== '') {
            sendData[data.id] = data.value;
          }
        }

        const res = await GenoGrammApi.saveClient(
          context.authToken,
          props.indexClient.id,
          sendData,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          queryClient.invalidateQueries(['geno-gramm-data']);
        }
        setIsLoading(false);
      } else snackbarUtils.error(context.i18n.redFieldsNeedToBeFilledOut);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  useEffect(() => {
    setClient({
      [ClientDataType.FIRST_NAME]: '',
      [ClientDataType.LAST_NAME]: '',
      [ClientDataType.SEX]: Sex.MALE,
    });
  }, [props.indexClient]);

  useEffect(() => {
    context.getAndRemoveGroupData(groupId);
  }, []);

  return (
    <Accordion
      square
      expanded={props.selected === groupId}
      disableGutters
      elevation={5}
      slotProps={{ transition: { unmountOnExit: true } }}
      sx={{
        ...AccordionStyle(colors.backgroundColor, colors.color),
      }}
    >
      <AccordionSummary
        onClick={() => props.setSelected(groupId)}
        expandIcon={
          <FontAwesomeIcon
            size="2x"
            color={theme.palette.primary.main}
            icon={faCaretDown}
          />
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            color={colors.color}
            icon={faPersonCirclePlus}
            size="2x"
            style={{
              padding: '0.5rem',
              marginLeft: '1.5rem',
            }}
          ></FontAwesomeIcon>
          <Typography sx={{ ml: 2 }}>
            {context.i18n.genoGrammAddPerson}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 2 }}>
        <Box sx={{ mt: 5 }}>
          <GenoGrammClientData isLoading={isLoading} client={client} />
          <Grid container spacing={2}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ p: 2, width: '100%' }}
                color="inherit"
                size="large"
                endIcon={
                  <FontAwesomeIcon
                    color={theme.palette.success.main}
                    icon={faCheck}
                  />
                }
                onClick={onSaveClient}
              >
                {context.i18n.save}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default GenoGrammAddPerson;
