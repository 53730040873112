import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { AppContext } from '../../../hooks/context';
import { useQueryClient } from 'react-query';
import { AdminApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { DateTimePicker, DateTimeRangePicker } from '@mui/x-date-pickers-pro';
import { UserSelect } from '../../client';

interface CreateOrgMessageDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateOrgMessageDialog: FC<CreateOrgMessageDialogProps> = (
  props: CreateOrgMessageDialogProps,
) => {
  const { open, onClose } = props;
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(
    null,
  );
  const [allUsers, setAllUsers] = useState(true);
  const [displayUsers, setDisplayUsers] = useState<string[]>([]);
  const [displayRoles, setDisplayRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const resetState = () => {
    setSubject('');
    setMessage('');
    setHasExpirationDate(false);
    setExpirationDate(null);
    setAllUsers(true);
    setDisplayUsers([]);
    setDisplayRoles([]);
  };

  useEffect(() => {
    if (!hasExpirationDate && expirationDate) {
      setExpirationDate(null);
    }
  }, [hasExpirationDate, expirationDate]);

  const createMessage = async () => {
    setLoading(true);
    await AdminApi.createOrgMessage(context.authToken, {
      subject,
      message,
      expirationDate: expirationDate ? expirationDate.toISOString() : null,
      displayUsers,
      displayRoles,
    })
      .then((res) => {
        snackbarUtils.success(res.message || context.i18n.success);
        closeAndReload();
      })
      .finally(() => setLoading(false));
  };

  const closeAndReload = () => {
    resetState();
    queryClient.invalidateQueries('listOrgMessages');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
    >
      <DialogTitle>{context.i18n.createOrgMessage}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            color="secondary"
            label={context.i18n.subject}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            value={subject}
            onChange={(e) => setSubject(e.currentTarget.value)}
            inputProps={{
              sx: { backgroundColor: 'white' },
            }}
          />
        </Box>
        <Box>
          <TextField
            color="secondary"
            label={context.i18n.message}
            size="medium"
            sx={{ mt: 2, width: '22rem' }}
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            inputProps={{
              sx: { backgroundColor: 'white' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') console.log('Enter');
            }}
            minRows={3}
            multiline
          />
        </Box>
        <Box>
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={hasExpirationDate}
                onClick={() => setHasExpirationDate(!hasExpirationDate)}
              />
            }
            label={context.i18n.hasExpirationDate}
          />
        </Box>
        {hasExpirationDate && (
          <Box display="flex" justifyContent="center">
            <DateTimePicker
              format="DD.MM.YYYY HH:mm"
              minDateTime={moment()}
              value={expirationDate}
              sx={{ width: '22rem' }}
              onChange={(date) => setExpirationDate(date)}
              timeSteps={{ minutes: 15 }}
              minutesStep={15}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              localeText={{
                start: context.i18n.startDate,
                end: context.i18n.endDate,
                startDate: context.i18n.startDate,
                endDate: context.i18n.endDate,
                startTime: context.i18n.startTime,
                endTime: context.i18n.endTime,
              }}
            />
          </Box>
        )}
        <Box>
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={allUsers}
                onClick={() => setAllUsers(!allUsers)}
              />
            }
            label={context.i18n.allUsers}
          />
        </Box>
        {!allUsers && (
          <UserSelect
            multiple
            setId={(ids) => setDisplayUsers(ids)}
            error={false}
            title={context.i18n.user}
            noShowUsers={[context.userId]}
          />
        )}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Button
            color="primary"
            variant="contained"
            id="createBtn"
            onClick={createMessage}
            className="formBtn"
            disabled={
              subject === '' ||
              message === '' ||
              (!allUsers && displayUsers.length === 0)
            }
            sx={{ color: 'white', mr: 'auto', ml: 'auto' }}
          >
            {loading ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              context.i18n.createOrgMessage
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrgMessageDialog;
