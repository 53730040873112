import { faX, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, InputAdornment, Button } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { theme } from '../../../utils';

interface ICD10FilterToolbarProps {
  searchText: string;
  setSearchText: (searchText: string) => void;
}

const ICD10FilterToolbar: FC<ICD10FilterToolbarProps> = (props) => {
  const context = useContext(AppContext);
  const [sText, setSText] = useState(props.searchText);

  const handleEnterKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      props.setSearchText(sText);
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        p: 1,
        mt: 1,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <TextField
        color="secondary"
        placeholder={context.i18n.codeSearch}
        value={sText}
        onChange={(e) => setSText(e.target.value)}
        onKeyDown={handleEnterKeyDown}
        InputProps={{
          endAdornment: (
            <>
              {sText ? (
                <InputAdornment
                  sx={{ color: theme.palette.secondary.main }}
                  position="end"
                >
                  <FontAwesomeIcon
                    onClick={() => {
                      setSText('');
                      props.setSearchText('');
                    }}
                    icon={faX}
                  />
                </InputAdornment>
              ) : (
                <Box sx={{ width: 20 }} />
              )}
            </>
          ),
          startAdornment: (
            <InputAdornment
              sx={{ color: theme.palette.secondary.main }}
              position="start"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        sx={{ ml: 1, height: '80%', mt: 'auto', mb: 'auto' }}
        onClick={() => props.setSearchText(sText)}
      >
        {context.i18n.searchBtn}
      </Button>
    </Box>
  );
};

export default ICD10FilterToolbar;
