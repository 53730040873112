import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../hooks/context';
import { GenoGrammApi } from '../../api';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ClientDataType, GenoGrammRelationType } from '@casecare/types';
import GenderIcon from '../../components/core/gender-icon/gender-icon';
import LeaderLine, { PathType, PlugType, SocketType } from 'leader-line-new';
import { DndContext } from '@dnd-kit/core';
import {
  GenoGrammDragable,
  GenoGrammDrawerPerson,
  GenoGrammDrawerPregnancy,
  GenoGrammPlaceHolderDroppable,
  GenoGrammPregnancyDragable,
} from '../../components/geno-gramm';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { loadTitle, queryClient, setDocumentTitle, theme } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faPerson,
  faPersonCirclePlus,
  faPersonPregnant,
  faSlash,
} from '@fortawesome/free-solid-svg-icons';
import { MenuWrapper } from '../../components/core';
import Navbar from '../../components/core/navbar/navbar';
import NavbarWrapper from '../../components/core/navbar-wrapper/navbar-wrapper';
import { AvatarTabButtonStyle } from '../../utils/styling/styling';

export const GenoGramm: FC = () => {
  const context = useContext(AppContext);
  const { clientId } = useParams();

  const [layers, setLayers] = useState<Record<string, any[]>>({});
  const [pregnancies, setPregnancies] = useState([]);
  const [client, setClient] = useState<any>(undefined);
  const [clients, setClients] = useState<any[]>([]);
  const [connections, setConnections] = useState<any[]>([]);
  const [selected, setSelected] = useState<string | undefined>(undefined);

  const [openDrawerPregnancy, setOpenDrawerPregnancy] = useState(false);
  const [openDrawerPerson, setOpenDrawerPerson] = useState(false);

  const lines = useRef<LeaderLine[]>([]);

  const setAccordionSelected = (newSelected: string | undefined) => {
    if (newSelected === selected) newSelected = undefined;
    setSelected(newSelected);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['geno-gramm-data', clientId],
    queryFn: () => {
      return GenoGrammApi.getGenoGramm(context.authToken, clientId);
    },
    enabled: context.authToken !== undefined && clientId !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setPregnancies(data.data.pregnancies);
        setClients(data.data.clients);
        setLayers(data.data.layers);
        setClient(data.data.clientData);
        setConnections(data.data.connections);
      }
    }
  }, [data, error, isLoading]);

  const handleDragEnd = async (handle: any) => {
    const item = handle.active.data.current;
    if (handle && handle.delta && item) {
      item.x = item.x + handle.delta.x;
      if (item.x <= 0) item.x = 0;
      try {
        await GenoGrammApi.changeOrder(context.authToken, clientId, {
          positionId: handle.active.id,
          x: item.x,
        });

        queryClient.invalidateQueries(['geno-gramm-data']);
      } catch (e) {
        console.error(e);
        snackbarUtils.error(context.i18n.errorTryAgainLater);
      }
    }
  };

  useEffect(() => {
    setDocumentTitle(context.i18n.genoGramm);
    return () => loadTitle();
  }, []);

  const rePositionLines = () => {
    for (const line of lines.current) {
      line.position();
    }
  };

  const removeLines = () => {
    for (const line of lines.current) {
      line.remove();
    }
  };

  useEffect(() => {
    removeLines();

    lines.current = [];
    const alreadyUsedIds: any[] = [];

    const defaultStartSocketGravity = 5;
    const defaultEndSocketGravity = 5;

    const defaultStartSocketGravityParent = 15;

    const defaultConf = {
      path: 'grid' as PathType,
      startPlug: 'behind' as PlugType,
      endPlug: 'behind' as PlugType,
      size: 3,
      endPlugSize: 0,
      gradient: false,
      color: 'black',
    };

    connections.map((connection: any) => {
      let startSocketGravity = defaultStartSocketGravity;
      let endSocketGravity = defaultEndSocketGravity;
      const startSocketGravityParent: any[] = [];
      const start = document.getElementById(connection.clientId);
      const end = document.getElementById(connection.relationId);

      if (start !== null && end !== null && start !== end) {
        switch (connection.type) {
          case GenoGrammRelationType.CHILD:
            if (!startSocketGravityParent[connection.clientId])
              startSocketGravityParent[connection.clientId] =
                defaultStartSocketGravityParent;
            lines.current.push(
              new LeaderLine(start, end, {
                ...defaultConf,
                startSocket: 'bottom',
                endSocket: 'top',
                // startSocketGravity: [
                //   0,
                //   startSocketGravityParent[connection.clientId],
                // ],
                // endSocketGravity: [0, 0],
              }),
            );
            startSocketGravityParent[connection.clientId] +=
              defaultStartSocketGravityParent;
            break;
          case GenoGrammRelationType.PARENT:
            if (!startSocketGravityParent[connection.clientId])
              startSocketGravityParent[connection.clientId] =
                defaultStartSocketGravityParent;
            lines.current.push(
              new LeaderLine(start, end, {
                ...defaultConf,
                startSocket: 'top',
                endSocket: 'bottom',
                // startSocketGravity: [
                //   0,
                //   startSocketGravityParent[connection.clientId],
                // ],
                // endSocketGravity: [0, 0],
              }),
            );
            startSocketGravityParent[connection.clientId] +=
              defaultStartSocketGravityParent;
            break;
          case GenoGrammRelationType.SIBLING:
            lines.current.push(
              new LeaderLine(start, end, {
                ...defaultConf,
                startSocket: 'top',
                endSocket: 'top',
                startSocketGravity: defaultStartSocketGravity,
                endSocketGravity: defaultEndSocketGravity,
              }),
            );
            break;
          case GenoGrammRelationType.EX:
          case GenoGrammRelationType.DIVORCED:
          case GenoGrammRelationType.SPOUSE:
          case GenoGrammRelationType.LIFE_PARTNER:
            startSocketGravity += defaultStartSocketGravity;
            endSocketGravity += defaultEndSocketGravity;
            lines.current.push(
              new LeaderLine(start, end, {
                ...defaultConf,
                startSocket: 'top',
                endSocket: 'top',
                startSocketGravity: startSocketGravity,
                endSocketGravity: endSocketGravity,
              }),
            );
            break;
        }
      }
    });

    rePositionLines();
  }, [layers, lines.current]);

  return (
    <NavbarWrapper title={''}>
      {!isLoading && client !== undefined && (
        <>
          <GenoGrammDrawerPerson
            clients={clients}
            indexClient={client}
            open={openDrawerPerson}
            onClose={() => setOpenDrawerPerson(false)}
            setSelected={setAccordionSelected}
            selected={selected}
          />
          <GenoGrammDrawerPregnancy
            pregnancies={pregnancies}
            client={client}
            indexId={clientId}
            open={openDrawerPregnancy}
            clientList={clients}
            onClose={() => setOpenDrawerPregnancy(false)}
          />
        </>
      )}
      <Grid container>
        <Grid overflow={'auto'} item xs={2}>
          <Tabs
            orientation="vertical"
            sx={{
              position: 'fixed',
              height: '100vh',
              top: '50%',
              bottom: '50%',
            }}
            value={false}
          >
            <Tab
              sx={{ ...AvatarTabButtonStyle }}
              label={'Personen'}
              onClick={() => setOpenDrawerPerson(true)}
              icon={
                <FontAwesomeIcon
                  size="2x"
                  color={theme.palette.primary.main}
                  icon={faPerson}
                />
              }
            />
            <Tab
              sx={{ ...AvatarTabButtonStyle }}
              label={'Schwangerschaften'}
              onClick={() => setOpenDrawerPregnancy(true)}
              icon={
                <FontAwesomeIcon
                  size="2x"
                  color={theme.palette.primary.main}
                  icon={faPersonPregnant}
                />
              }
            />
          </Tabs>
        </Grid>
        {/* <Typography m={3} variant="h4">
          {context.i18n.genoGrammFor}
          {': '}
          {client && client[ClientDataType.FIRST_NAME]}{' '}
          {client && client[ClientDataType.LAST_NAME]}
          <GenderIcon sex={client && client[ClientDataType.SEX]}></GenderIcon>
        </Typography> */}
        <Grid item container xs={10} sx={{ position: 'relative' }}>
          <DndContext onDragEnd={handleDragEnd} onDragMove={rePositionLines}>
            {Object.keys(layers).map((v: any) => (
              <Grid item xs={12} key={v}>
                <Box
                  mt={
                    layers[v].length <= 6
                      ? '75px'
                      : layers[v].length * 10 + 'px'
                  }
                  mb={
                    layers[v].length <= 6
                      ? '75px'
                      : layers[v].length * 10 + 'px'
                  }
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={'wrap'}
                >
                  {layers[v].map((c) => (
                    <Box key={v + '-' + c.id}>
                      {c.isPregnancy ? (
                        <GenoGrammPregnancyDragable pregnancy={c} />
                      ) : (
                        <GenoGrammDragable
                          client={c}
                          isIndex={c.id === clientId}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </Grid>
            ))}
          </DndContext>
        </Grid>
      </Grid>
    </NavbarWrapper>
  );
};
