import { AccordionDetails, Button, Grid, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  faCalculator,
  faCheck,
  faMap,
  faPersonCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import { EcoMapTabMenuType } from '../../../types/eco-map-tab-menu-type';
import EcoMapAccordion from '../eco-map-accordion/eco-map-accordion';
import { theme } from '../../../utils';
import EcoMapClientData from '../eco-map-client-menu/eco-map-client-data/eco-map-client-data';
import { ClientDataType, Sex } from '@casecare/types';
import EcoMapEdit from './eco-map-edit/eco-map-edit';
import EcoMapApi from '../../../api/eco-map/eco-map';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EcoMapCalculation from '../eco-map-calculation/eco-map-calculation';
import MapIcon from '../../icons/map-icon';
import EcoMapClientDataCreate from '../eco-map-client-menu/eco-map-client-data/eco-map-client-data-create';
interface EcoMapMenuProps {
  reloadData: () => void;
  ecoMap: any;
  client: any;
  selected: string | undefined;
  setSelected: (selected: string | undefined) => void;
  family: any;
}
const EcoMapMenu: FC<EcoMapMenuProps> = (props) => {
  const { clientId } = useParams();
  const context = useContext(AppContext);
  const groupId = 'Eco-Map-Client-Data-CREATE';

  const colors = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  };

  const onSaveClient = async () => {
    try {
      let error = '';
      const groupData = context.getGroupData(groupId);
      const sendData: Record<string, any> = {};
      for (const data of groupData) {
        if (data.error !== null) error += data.error + '\n';
        sendData[data.id] = data.value;
      }
      if (typeof sendData['isDead'] !== 'undefined')
        if (sendData.isDead.length > 0) sendData.isDead = true;
        else sendData.isDead = false;

      if (typeof sendData['isRealPerson'] !== 'undefined')
        if (sendData.isRealPerson === 1) sendData.isRealPerson = true;
        else sendData.isRealPerson = false;

      if (
        typeof sendData['role'] === 'undefined' &&
        typeof sendData['relationship'] === 'undefined'
      )
        error += context.i18n.roleRelationShipRequired + '\n';

      if (error == '' && groupData.length > 0) {
        const res = await EcoMapApi.saveClient(
          context.authToken,
          clientId,
          sendData,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          context.removeGroupData(groupId);
          props.reloadData();
          props.setSelected(undefined);
        }
      } else snackbarUtils.error(error);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <>
      <EcoMapAccordion
        expanded={props.selected === EcoMapTabMenuType.EDITMAP}
        onChange={() => props.setSelected(EcoMapTabMenuType.EDITMAP)}
        svg={<MapIcon />}
        label={context.i18n.ecoMapEdit}
        color={colors.color}
        background={colors.backgroundColor}
      >
        <AccordionDetails>
          <EcoMapEdit
            ecoMap={props.ecoMap}
            reloadData={props.reloadData}
            onCancel={() => {
              props.setSelected(undefined);
            }}
          ></EcoMapEdit>
        </AccordionDetails>
      </EcoMapAccordion>

      <EcoMapAccordion
        expanded={props.selected === EcoMapTabMenuType.ADDPERSON}
        onChange={() => props.setSelected(EcoMapTabMenuType.ADDPERSON)}
        icon={faPersonCirclePlus}
        label={context.i18n.ecoMapAddPerson}
        color={colors.color}
        background={colors.backgroundColor}
      >
        <AccordionDetails sx={{ m: 2 }}>
          <EcoMapClientDataCreate />
          <Grid container spacing={2}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ p: 2, width: '100%' }}
                color="inherit"
                size="large"
                endIcon={
                  <FontAwesomeIcon
                    color={theme.palette.success.main}
                    icon={faCheck}
                  />
                }
                onClick={onSaveClient}
              >
                {context.i18n.save}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </EcoMapAccordion>
      <EcoMapAccordion
        expanded={props.selected === EcoMapTabMenuType.CALCULATION}
        onChange={() => props.setSelected(EcoMapTabMenuType.CALCULATION)}
        icon={faCalculator}
        label={context.i18n.keyFigures}
        color={colors.color}
        background={colors.backgroundColor}
      >
        <AccordionDetails sx={{ m: 2 }}>
          <EcoMapCalculation client={props.client.id} ecoMap={props.ecoMap} />
        </AccordionDetails>
      </EcoMapAccordion>
    </>
  );
};

export default EcoMapMenu;
