import {
  AccordionDetails,
  Box,
  BoxProps,
  Grid,
  IconButton,
  Tabs,
  Typography,
} from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../../hooks/context';
import { theme } from '../../../utils';

interface EcoMapAvatarIconProps extends BoxProps {
  src: string;
  height?: string;
  width?: string;
  borderColor?: string;
  isdead?: boolean;
}

const EcoMapAvatarIcon: FC<EcoMapAvatarIconProps> = (props) => {
  return (
    <Box
      component="img"
      sx={{
        height: props.height ? props.height : 40,
        width: props.width ? props.width : 40,
        borderStyle: 'solid',
        borderWidth: 2,
        p: 0.5,
        filter: props.isdead ? 'grayscale(1)' : 'none',
        borderRadius: 100,
        borderColor: props.borderColor ? props.borderColor : 'primary.main',
        [theme.breakpoints.down('xl')]: {
          height: props.height ? props.height : 20,
          width: props.width ? props.width : 20,
        },
      }}
      {...props}
      /* FIXED: im getting an warning and i cant fix it
        Warning: Received `false` for a non-boolean attribute `isdead`.
        If you want to write it to the DOM, pass a string instead: isdead="false" or isdead={value.toString()}.
        If you used to conditionally omit it with isdead={condition && value}, pass isdead={condition ? value : undefined} instead.
      */
      isdead={undefined}
    />
  );
};

export default EcoMapAvatarIcon;
