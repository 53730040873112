import { createTheme } from '@mui/material';
import { titleSuffix } from '../constants';
import { i18n } from '../i18n/i18n';
import { deDE } from '@mui/material/locale';

const breakpoints = createTheme();

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#32436E',
      },
      secondary: {
        main: '#91A3D9',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      body1: {
        fontSize: '1rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '0.8rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '0.7rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '0.6rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '0.5rem',
        },
        [breakpoints.breakpoints.down('xs')]: {
          fontSize: '0.4rem',
        },
      },
      button: {
        fontSize: '1rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '0.8rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '0.7rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '0.6rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '0.5rem',
        },
        [breakpoints.breakpoints.down('xs')]: {
          fontSize: '0.4rem',
        },
      },
      h1: {
        fontSize: '2.2rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '2rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '1.8rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '1.6rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '1.4rem',
        },
        [breakpoints.breakpoints.down('xs')]: {
          fontSize: '1.2rem',
        },
      },
    },
  },
  i18n.getLanguage() !== 'en' ? deDE : {},
);

export const setDocumentTitle = (title?: string) => {
  if (!title) {
    document.title = titleSuffix;
    return;
  }
  document.title = `${title} | ${titleSuffix}`;
};

export const loadTitle = () => {
  document.title = `... | ${titleSuffix}`;
};

export const getSanitizedFileName = (input: string | undefined) => {
  if (!input) return '';
  return input
    .replaceAll(' ', '_')
    .replaceAll('Ä', 'Ae')
    .replaceAll('ä', 'ae')
    .replaceAll('Ö', 'Oe')
    .replaceAll('ö', 'oe')
    .replaceAll('Ü', 'Ue')
    .replaceAll('ü', 'ue')
    .replaceAll('ß', 'ss');
};
