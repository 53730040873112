import {
  faCheckCircle,
  faEject,
  faExclamationCircle,
  faFileCircleXmark,
  faFileExport,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  gridClasses,
} from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import moment from 'moment';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { ProtocolApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import { Protocol, ProtocolDatagridElement } from '../../../../types';
import { parseDateTimeString, queryClient } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import {
  DeleteProtocolDialog,
  EditProtocolDialog,
  ExportProtocolDialog,
  RejectProtocolDialog,
  ReleaseProtocolHoursDialog,
} from '../../../dialogs';
import ProtocolFilterToolbar from './protocol-filter-toolbar';
import ProtocolTableFooter from './protocol-table-footer';
import { useTheme } from '@emotion/react';
import './component.css';
import { getSanitizedFileName } from '../../../../utils/core/misc-utils';

const ProtocolTable: FC<{
  clientId?: string;
  familyId?: string;
  familyIds?: string[];
  supervisorIds: string[] | undefined;
  name: string | undefined;
  hideHeader?: boolean;
  noPaper?: boolean;
  date?: moment.Moment;
  billing?: boolean;
  billingShowAll?: boolean;
  rejected?: boolean;
  released?: boolean;
}> = (props) => {
  const context = useContext(AppContext);

  const { billing, billingShowAll, released, rejected } = props;

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rows, setRows] = useState<ProtocolDatagridElement[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment(props.date).startOf('month').startOf('day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    moment(props.date).endOf('month'),
  );
  const [supervisorIds, setSupervisorIds] = useState([]);
  const [selectedClientHelpForms, setSelectedClientHelpForms] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openEditBulletPoints, setOpenEditBulletPoints] = useState(false);
  const [protocol, setProtocol] = useState<Protocol | undefined>();
  const [openRelaseDialog, setOpenReviewDialog] = useState(false);
  const [isInReview, setIsInReview] = useState(false);
  const [openDeleteProtocolDialog, setOpenDeleteProtocolDialog] =
    useState(false);

  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
  const [releaseProtocolId, setReleaseProtocolId] = useState<string>();
  const theme = useTheme();

  const [protocolIds, setProtocolIds] = useState([]);

  const [availableSupervisorIds, setAvailableSupervisorIds] =
    useState<string[]>();

  const { isLoading } = useQuery(
    [
      'protocols',
      pageSize,
      pageNum,
      sortModel,
      endDate,
      startDate,
      supervisorIds,
      selectedClientHelpForms,
      released,
      rejected,
    ],
    () => {
      const data: any = Object.assign(
        {
          pageNum,
          pageSize,
          sortModel,
          startDate,
          endDate,
          userIds: supervisorIds,
          helpFormIds: selectedClientHelpForms.map((v: any) => v.id),
        },
        props.clientId && {
          clientId: props.clientId,
        },
        props.familyId && {
          familyId: props.familyId,
        },
        billing &&
          billingShowAll && {
            billingShowAll,
          },
        released && { released },
        rejected && { rejected },
      );
      return ProtocolApi.getProtocols(context.authToken, data);
    },
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setRows(
          res.data.protocols.map((protocol: any) => {
            return {
              id: protocol.id,
              contentOfCare: protocol.contentOfCare,
              helpForm: protocol.helpForm?.name,
              helpFormId: protocol.helpForm?.id,
              clientHelpFormId: protocol.clientHelpFormId,
              supervisorIds: protocol.supervisorIds,
              supervisors: protocol.supervisorNames,
              endDate: protocol.endDate,
              startDate: protocol.startDate,
              hours: protocol.hours,
              place: protocol.place,
              personsPresent: protocol.personsPresent,
              billable: protocol.billable,
              inReview: protocol.inReview,
              isReleased: protocol.isReleased,
              rejected: protocol.rejected,
              rejectionReason: protocol.rejectionReason,
              releaseProtocolId: protocol.releaseProtocolId,
            };
          }),
        );
        setIsInReview(res.data.inReview);
        setRowCount(res.data.totalCount);
        setAvailableSupervisorIds(
          res.data.protocols
            .flatMap((protocol: any) => protocol.supervisorIds || [])
            .filter(
              (id: any, index: any, self: any) => self.indexOf(id) === index,
            ),
        );
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onExport = async (date: Date, helpFormId?: string) => {
    let res;

    if (props.familyId) {
      res = await ProtocolApi.exportFamilyProtocols(
        context.authToken,
        props.familyId,
        date,
        helpFormId
          ? {
              helpFormId,
            }
          : undefined,
      );
    } else {
      res = await ProtocolApi.exportClientProtocols(
        context.authToken,
        props.clientId,
        date,
        helpFormId
          ? {
              helpFormId,
            }
          : undefined,
      );
    }
    const fileName = res.headers['content-disposition']
      ? res.headers['content-disposition'].split('filename=')[1].split(';')[0]
      : `${context.i18n.protocol}_${getSanitizedFileName(props.name)}_${moment(
          date,
        ).format('MMYYYY')}.docx`;
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();

    setOpenExport(false);
  };

  const onReviewProtocols = async () => {
    try {
      const body = Object.assign(
        {
          protocolIds,
        },
        props.clientId && {
          clientId: props.clientId,
        },
        props.familyId && {
          familyId: props.familyId,
        },
      );

      const res = await ProtocolApi.reviewProtocol(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      setOpenReviewDialog(false);
      setProtocolIds([]);
      queryClient.invalidateQueries(['protocols']);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const updateCell = async (params: any, value: string) => {
    try {
      const body = Object.assign(
        {
          userIds: params.row.supervisorIds,
          startDate: moment(params.row.startDate, 'DD.MM.YYYY | HH:mm'),
          endDate: moment(params.row.endDate, 'DD.MM.YYYY | HH:mm'),
          hours: params.row.hours,
          billable: params.row.billable,
          helpFormId: params.row.helpFormId,
        },
        params.colDef.field === 'place' && {
          place: value,
          personsPresent: params.row.personsPresent,
          contentOfCare: params.row.contentOfCare,
        },
        params.colDef.field === 'personsPresent' && {
          personsPresent: value,
          contentOfCare: params.row.contentOfCare,
          place: params.row.place,
        },
        params.colDef.field === 'contentOfCare' && {
          contentOfCare: value,
          place: params.row.place,
          personsPresent: params.row.personsPresent,
        },
      );

      if (value) {
        const res = await ProtocolApi.updateProtocol(
          context.authToken,
          params.id,
          body,
        );

        if (res.message) {
          snackbarUtils.success(res.message);
        }
      }

      queryClient.invalidateQueries('protocols');
    } catch (e) {
      console.error(e);
      // snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const withdrawProtocol = async (protocolId: string) => {
    try {
      const body = {
        protocolId,
      };

      const res = await ProtocolApi.withdrawProtocol(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries(['protocols']);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteProtocol = async () => {
    try {
      if (protocol) {
        const body = {
          protocolId: protocol.id,
        };

        const res = await ProtocolApi.deleteProtocol(context.authToken, body);

        if (res.message) {
          snackbarUtils.success(res.message);
        }

        queryClient.invalidateQueries(['protocols']);
      }
    } catch (e) {
      console.error(e);
      // snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
    setOpenDeleteProtocolDialog(false);
    setProtocol(undefined);
  };

  const getUserActions = (params: GridRenderCellParams) => (
    <>
      {params.row.rejected ? (
        <>
          <Tooltip
            title={`${context.i18n.rejectionReason}: ${
              params.row.rejectionReason || ''
            }`}
          >
            <IconButton disableRipple color="error" sx={{ height: 39 }}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </IconButton>
          </Tooltip>
        </>
      ) : params.row.isReleased ? (
        <>
          <Tooltip title={context.i18n.protocolIsReleased}>
            <IconButton disableRipple color="success" sx={{ height: 39 }}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Box sx={{ width: '40px' }}>&nbsp;</Box>
      )}
      <Tooltip title={context.i18n.editProtocol}>
        <IconButton
          disableRipple
          color="primary"
          id={'edit-button-' + params.id}
          sx={{ height: 39 }}
          onClick={() => {
            setProtocol(params.row);
            setOpenDialog(true);
          }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </IconButton>
      </Tooltip>
      {params.row.inReview &&
        !params.row.isReleased &&
        (params.row.supervisorIds.includes(context.user?.userId) ||
          context.user?.role !== 'USER') && (
          <Tooltip title={context.i18n.withdrawProtocol}>
            <IconButton
              disableRipple
              color="primary"
              id={'withdraw-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() => {
                withdrawProtocol(params.row.id);
              }}
            >
              <FontAwesomeIcon icon={faEject} />
            </IconButton>
          </Tooltip>
        )}
      {!params.row.inReview &&
        (params.row.supervisorIds.includes(context.user?.userId) ||
          context.user?.role !== 'USER') && (
          <Tooltip title={context.i18n.deleteProtocol}>
            <IconButton
              disableRipple
              color="error"
              id={'delete-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() => {
                setProtocol(rows.find((row) => row.id === params.id));
                setOpenDeleteProtocolDialog(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        )}
    </>
  );

  const getBillingActions = (params: GridRenderCellParams) => {
    const row = params.row;
    return (
      <>
        {params.row.rejected ? (
          <>
            <Tooltip
              title={`${context.i18n.rejectionReason}: ${
                params.row.rejectionReason || ''
              }`}
            >
              <IconButton disableRipple color="error" sx={{ height: 39 }}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </IconButton>
            </Tooltip>
          </>
        ) : params.row.isReleased ? (
          <>
            <Tooltip title={context.i18n.protocolIsReleased}>
              <IconButton disableRipple color="success" sx={{ height: 39 }}>
                <FontAwesomeIcon icon={faCheckCircle} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Box sx={{ width: '40px' }}>&nbsp;</Box>
        )}
        <Tooltip title={context.i18n.editProtocol}>
          <IconButton
            disableRipple
            color="primary"
            id={'edit-button-' + params.id}
            sx={{ height: 39 }}
            onClick={() => {
              setProtocol(params.row);
              setOpenDialog(true);
            }}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </IconButton>
        </Tooltip>
        {row.inReview ? (
          <>
            <Tooltip title={context.i18n.reject}>
              <IconButton
                disableRipple
                id={'reject-button-' + params.id}
                key={'reject-button-' + params.id}
                color="error"
                sx={{
                  height: 39,
                }}
                disabled={!context.basicActive}
                onClick={() => {
                  setReleaseProtocolId(row.releaseProtocolId);
                  setOpenRejectionDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faFileCircleXmark} />
              </IconButton>
            </Tooltip>
            {!row.isReleased && (
              <Tooltip title={context.i18n.release}>
                <IconButton
                  disableRipple
                  id={'release-button-' + params.id}
                  key={'release-button-' + params.id}
                  color="success"
                  sx={{
                    height: 39,
                  }}
                  disabled={!context.basicActive}
                  onClick={() => {
                    onReleaseProtocol(row.releaseProtocolId);
                  }}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <Box sx={{ width: '40px' }}>&nbsp;</Box>
        )}
      </>
    );
  };

  const tableColumns: Array<GridColDef> = [
    {
      field: 'helpForm',
      headerName: context.i18n.helpFormShort,
      flex: 0.5,
      hideable: false,
    },
    {
      field: 'supervisors',
      headerName: context.i18n.supervisor,
      flex: 1,
      hideable: false,
      sortable: false,
      valueFormatter: (value: any) =>
        value.map((s: any) => s.handle).join(', '),
    },
    {
      field: 'startDate',
      headerName: context.i18n.protocolBegin,
      hideable: true,
      flex: 0.5,
      valueFormatter: (value) => parseDateTimeString(value),
    },
    {
      field: 'endDate',
      headerName: context.i18n.protocolEnd,
      flex: 0.5,
      hideable: true,
      valueFormatter: (value) => parseDateTimeString(value),
    },
    {
      field: 'hours',
      headerName: context.i18n.hoursShort,
      flex: 0.25,
      align: 'center',
      renderCell: (params) => (
        <Typography display="flex">
          {params.row.billable ? params.value : '-'}
        </Typography>
      ),
    },
    {
      field: 'place',
      headerName: context.i18n.place,
      flex: 0.3,
      editable: false,
      cellClassName: 'edit',
    },
    {
      field: 'personsPresent',
      headerName: context.i18n.persons,
      flex: 0.3,
      editable: false,
      cellClassName: 'edit',
    },
    {
      field: 'contentOfCare',
      headerName: context.i18n.content,
      flex: 2,
      // editable: true,
      cellClassName: 'edit',
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderHeader: () => {
        return billing ? null : (
          <Box>
            <Tooltip title={context.i18n.addProtocol}>
              <IconButton
                disableRipple
                color="primary"
                disabled={isInReview}
                onClick={() => {
                  setProtocol(undefined);
                  setOpenDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      renderCell: (params) => (
        <Box sx={{ mr: 'auto', display: 'flex' }}>
          {billing ? getBillingActions(params) : getUserActions(params)}
        </Box>
      ),
    },
  ];

  const onRejectProtocol = async (rejectText: string) => {
    try {
      const body = Object.assign(
        {
          releaseProtocolId,
          rejectText,
        },
        props.clientId && {
          clientId: props.clientId,
        },
        props.familyId && {
          familyId: props.familyId,
        },
      );

      const res = await ProtocolApi.rejectProtocol(context.authToken, body);

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      setOpenRejectionDialog(false);
      queryClient.invalidateQueries('protocols');
      setReleaseProtocolId(undefined);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  const onReleaseProtocol = async (id?: string) => {
    try {
      const res = await ProtocolApi.releaseProtocol(context.authToken, {
        releaseProtocolId: id || releaseProtocolId,
        clientId: props.clientId,
        familyId: props.familyId,
      });

      if (res.message) {
        snackbarUtils.success(res.message);
      }

      queryClient.invalidateQueries('protocols');
      setReleaseProtocolId(undefined);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Paper
      sx={{
        maxHeight: '80vh',
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
        ...(billing && {
          m: 0,
          boxShadow: 'none',
        }),
      }}
    >
      <DataGridPro
        sx={{
          m: 2,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          display: 'flex',
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '& .edit': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
        }}
        slotProps={{
          toolbar: {
            startDate,
            endDate,
            supervisorIds,
            selectedClientHelpForms,
            setSelectedClientHelpForms,
            setStartDate,
            setEndDate,
            setSupervisorIds,
            onExport: () => setOpenExport(true),
            clientId: props.clientId,
            onReleaseHours: () => setOpenReviewDialog(true),
            disableRelease: protocolIds.length === 0,
            familyId: props.familyId,
            billing,
            availableSupervisorIds,
          },
          // footer: {
          //   endDate,
          //   clientId: props.clientId,
          //   familyId: props.familyId,
          // },
        }}
        slots={{
          toolbar(props: any) {
            return ProtocolFilterToolbar(props);
          },
          // footer: ProtocolTableFooter,
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        columnBufferPx={4}
        onRowDoubleClick={(params) => {
          setProtocol(params.row);
          setOpenDialog(true);
        }}
        // cell edit settings
        editMode="cell"
        onCellEditStop={(params, event: any) =>
          updateCell(params, event.target.value)
        }
        // Selection settings
        checkboxSelection={!billing}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids: any) => {
          setProtocolIds(ids);
        }}
        isRowSelectable={(params) => !params.row.inReview}
        rowSelectionModel={protocolIds}
        // Pagination settings
        paginationMode="server"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={rowCount}
        pagination
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
        getRowClassName={(params) => {
          if (billing) {
            if (params.row.inReview && !params.row.isReleased) return 'review';
            return 'grey-row-bg';
          }
          return params.row.isReleased ? 'grey-row-bg' : '';
        }}
      />
      <ProtocolTableFooter
        endDate={endDate}
        clientId={props.clientId}
        familyId={props.familyId}
      />
      {billing && (
        <RejectProtocolDialog
          open={openRejectionDialog}
          onClose={() => setOpenRejectionDialog(false)}
          onReturn={onRejectProtocol}
        />
      )}
      <EditProtocolDialog
        clientId={props.clientId}
        familyId={props.familyId}
        open={openDialog}
        protocol={protocol}
        onClose={() => {
          setTimeout(() => queryClient.invalidateQueries(['protocols']), 500);
          setOpenDialog(false);
        }}
        showReleaseButton
        submitOnSaveEnabled={!billing}
        onRelease={() => {
          if (billing && protocol) {
            onReleaseProtocol(protocol.releaseProtocolId);
          }
        }}
        adminView={billing || context.user?.role !== 'USER'}
        released={released}
      />
      <ExportProtocolDialog
        open={openExport}
        onClose={() => setOpenExport(false)}
        onSelect={(date, helpFormId?) => {
          onExport(date, helpFormId);
        }}
        clientId={props.clientId}
        familyId={props.familyId}
        month={endDate}
      />
      <ReleaseProtocolHoursDialog
        open={openRelaseDialog}
        onClose={() => setOpenReviewDialog(false)}
        onRelease={onReviewProtocols}
      />
      <DeleteProtocolDialog
        open={openDeleteProtocolDialog}
        onClose={() => setOpenDeleteProtocolDialog(false)}
        onDelete={deleteProtocol}
      />
    </Paper>
  );
};

export default ProtocolTable;
