import {
  AccordionDetails,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tabs,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import EcoMapAccordion from '../eco-map-accordion/eco-map-accordion';
import { TabScrollButtonEcoMapStyle } from '../../../utils/styling/styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../../utils';
import { ClientDataType } from '@casecare/types';
import EcoMapClientData from './eco-map-client-data/eco-map-client-data';
import { useParams } from 'react-router-dom';
import EcoMapClientConnection from './eco-map-client-connection/eco-map-client-connection';
import EcoMapApi from '../../../api/eco-map/eco-map';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface EcoMapClientMenuProps {
  connectedClients: any[];
  setConnectedClients: (client: any) => void;
  selected: string | undefined;
  setSelected: (selected: string | undefined) => void;
  ecoMapId: string;
  clientId: string;
  family: any[];
  reloadData?: (all: boolean) => void;
}

const EcoMapClientMenu: FC<EcoMapClientMenuProps> = (props) => {
  const context = useContext(AppContext);
  const [connectedClients, setConnectedClients] = useState<any[]>(
    props.connectedClients,
  );

  const [saveLoading, setSaveLoading] = useState(false);

  const updateClient = (client: any) => {
    const index = connectedClients.findIndex((data) => {
      return data.id === client.id;
    });
    if (index >= 0) {
      connectedClients[index] = Object.assign({ ...client });
      props.setConnectedClients(connectedClients);
    }
  };

  const onSave = async (client: any) => {
    if (!saveLoading) {
      try {
        setSaveLoading(true);
        let error = '';
        const sendData: Record<string, any> = {};

        const groupIdConnections = 'Eco-Map-Client-Connections-' + client.id,
          groupIdClient = 'Eco-Map-Client-Data-' + client.id;

        const dataErrors = context.getGroupDataError([
          groupIdClient,
          groupIdConnections,
        ]);

        for (const dataError of dataErrors) {
          error += dataError.error + '\n';
        }

        if (error == '') {
          // SAVE connections //
          let i = 0;
          for (const data of client.ecoMapFromConnections) {
            if (
              data.id === undefined &&
              data.color &&
              data.clientFromId &&
              data.clientToId
            ) {
              const createConnRes = await EcoMapApi.createConnection(
                context.authToken,
                props.ecoMapId,
                {
                  color: data.color,
                  clientFromId: data.clientFromId,
                  clientToId: data.clientToId,
                },
              );
              if (createConnRes.data) data.id = createConnRes.data;
            } else if (data.id && data.color) {
              await EcoMapApi.editConnection(context.authToken, data.id, data);
            } else if (data.id && data.color === undefined) {
              await EcoMapApi.deleteConnection(context.authToken, data.id);
              client.ecoMapFromConnections.splice(i, 1);
            }
            i++;
          }

          // SAVE client data //
          const groupDataClient = context.getGroupData(groupIdClient);
          for (const data of groupDataClient) {
            sendData[data.id] = data.value;
          }
          if (typeof sendData['isDead'] !== 'undefined')
            if (sendData.isDead.length > 0) sendData.isDead = true;
            else sendData.isDead = false;

          if (typeof sendData['isRealPerson'] !== 'undefined')
            if (sendData.isRealPerson === 1) sendData.isRealPerson = true;
            else sendData.isRealPerson = false;

          if (sendData['family'] === '') {
            sendData['family'] = null;
            sendData['referencePerson'] = true;
          } else {
            sendData['referencePerson'] = false;
          }

          if (client.id) sendData.id = client.id;
          const res = await EcoMapApi.saveClient(
            context.authToken,
            props.clientId,
            sendData,
          );
          if (res.message) {
            context.removeGroupData(groupIdClient);
            context.removeGroupData(groupIdConnections);
            updateClient(
              Object.assign({ ...client, ...sendData, id: res.data.id }),
            );
            props.reloadData && props.reloadData(false);
            snackbarUtils.success(res.message);
          }
        } else snackbarUtils.error(error);
      } catch (e) {
        console.error(e);
        snackbarUtils.error(context.i18n.errorTryAgainLater);
      }
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    setConnectedClients(props.connectedClients);
  }, [props.connectedClients]);

  return (
    <>
      {connectedClients.map((client) => (
        <EcoMapAccordion
          key={client.id}
          expanded={props.selected === client.id}
          onClick={() => props.setSelected(client.id)}
          removed={client.removedInEcoMaps.length > 0 ? true : undefined}
          label={
            client[ClientDataType.FIRST_NAME] +
            ' ' +
            client[ClientDataType.LAST_NAME] +
            (client.id === props.clientId
              ? ''
              : (client.role ? ' (' + client.role + ')' : '') +
                (client.relationship
                  ? ' (' + context.i18n[client.relationship] + ')'
                  : '')) +
            (client.ecoMapFromConnections.length
              ? ' - ' +
                context.i18n.connections +
                ': ' +
                client.ecoMapFromConnections.length
              : '')
          }
          image={client.avatar && client.avatar.iconPath}
          color={theme.palette.primary.main}
          elevation={0}
          avatar={{ isdead: client.isDead }}
        >
          <AccordionDetails sx={{ m: 2, maxHeight: 'unset !important' }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography
                  color={'primary.main'}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {context.i18n.connections}
                </Typography>
                <Grid item xs={12}>
                  <EcoMapClientConnection
                    client={client}
                    connectedClients={connectedClients}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <EcoMapClientData
                  // families={props.families}
                  client={client}
                />
              </Grid>
              <Grid item xs={6}>
                {client.id && client.id != props.clientId && (
                  <>
                    {client.removedInEcoMaps.length > 0 ? (
                      <Button
                        variant="contained"
                        sx={{ p: 2, width: '100%' }}
                        color="inherit"
                        onClick={async () => {
                          const res = await EcoMapApi.deleteEcoMapRemovedClient(
                            context.authToken,
                            client.id,
                            props.ecoMapId,
                          );
                          if (res.message) {
                            snackbarUtils.success(res.message);
                            props.reloadData && props.reloadData(true);
                          }
                        }}
                        endIcon={
                          <FontAwesomeIcon
                            color={theme.palette.success.main}
                            icon={faCheck}
                          />
                        }
                      >
                        {context.i18n.addPerson}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ p: 2, width: '100%' }}
                        color="inherit"
                        onClick={async () => {
                          const res = await EcoMapApi.addEcoMapRemovedClient(
                            context.authToken,
                            client.id,
                            props.ecoMapId,
                          );
                          if (res.message) {
                            snackbarUtils.success(res.message);
                            props.reloadData && props.reloadData(true);
                          }
                        }}
                        endIcon={
                          <FontAwesomeIcon
                            color={theme.palette.error.main}
                            icon={faX}
                          />
                        }
                      >
                        {context.i18n.removePerson}
                      </Button>
                    )}
                  </>
                )}
              </Grid>
              {client.removedInEcoMaps.length <= 0 && (
                <Grid item xs={6}>
                  <Button
                    disabled={saveLoading}
                    variant="contained"
                    sx={{ p: 2, width: '100%' }}
                    color="inherit"
                    size="large"
                    endIcon={
                      saveLoading ? (
                        <></>
                      ) : (
                        <FontAwesomeIcon
                          color={theme.palette.success.main}
                          icon={faCheck}
                        />
                      )
                    }
                    onClick={() => onSave(client)}
                  >
                    {saveLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>{context.i18n.save}</>
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </EcoMapAccordion>
      ))}
    </>
  );
};

export default EcoMapClientMenu;
