import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { FormElement } from '../../client';
import {
  ClientDataType,
  FormFieldType,
  GenoGrammRelationType,
} from '@casecare/types';
import { AppContext } from '../../../hooks/context';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarDeadIcon from '../../avatar/avatar-dead-icon/avatar-dead-icon';
import EcoMapAvatarIcon from '../../eco-map/eco-map-avatar-icon/eco-map-avatar-icon';
import { FormFieldStyle } from '../../../types/form-element-props';
import { GenoGrammClient } from '../../../types';

interface GenoGrammRelationProps {
  client: any;
  setClient: (client: any) => void;
  clients: any;
  indexId: string | undefined;
}

export const GenoGrammRelation: FC<GenoGrammRelationProps> = (props) => {
  const context = useContext(AppContext);

  const [client, setClient] = useState(props.client);
  const [clients, setClients] = useState(props.clients);
  const [filteredClients, setFilteredClients] = useState(props.clients);

  const groupId = 'Geno-Gramm-Client-Relations-' + client.id;

  useEffect(() => {
    setClient(props.client);
  }, [props.client]);

  useEffect(() => {
    setClients(props.clients);
  }, [props.clients]);

  useEffect(() => {
    const filtered = clients.filter(
      (cl: any) =>
        !client.genoGrammRelations.find((r: any) => r.relationId === cl.id) &&
        cl.id != client.id,
    );
    setFilteredClients(filtered);
  }, [client, clients]);

  const viewToClientData = (relation: any, index: any) => {
    const clientData = props.clients.find(
      (c: any) => c.id === relation.relationId,
    );
    if (clientData)
      return (
        <Box sx={{ mt: 5, mb: 2 }}>
          <Grid container>
            <Grid item xs={10}>
              <Divider textAlign="left">
                <Typography>
                  {clientData[ClientDataType.FIRST_NAME]}{' '}
                  {clientData[ClientDataType.LAST_NAME]}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={2}>
              <Divider textAlign="left">
                <Button
                  onClick={() => {
                    const newClient = client;
                    newClient.genoGrammRelations[index] = {
                      ...newClient.genoGrammRelations[index],
                      delete: true,
                    };
                    props.setClient({ ...newClient });
                  }}
                  color={'error'}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              </Divider>
            </Grid>
          </Grid>
        </Box>
      );
    else return <></>;
  };

  return (
    <>
      <Box sx={{ m: -2 }}>
        <FormElement
          title={context.i18n.relationShips}
          hideHeader
          type={FormFieldType.DROPDOWN}
          value={''}
          clearOnSelect
          id={client.id + '-select'}
          onChange={(val: any) => {
            const newClient = client;

            newClient.genoGrammRelations.push({
              relationId: val,
              clientId: client.id,
              type: '',
            });

            props.setClient({ ...newClient });
          }}
          fieldOptions={filteredClients.map((c: any) => {
            return {
              key: c.id,
              label: (
                <>
                  <Typography>
                    {c[ClientDataType.FIRST_NAME]} {c[ClientDataType.LAST_NAME]}
                  </Typography>
                </>
              ),
            };
          })}
        />
      </Box>
      {client.genoGrammRelations.map((r: any, index: any) => (
        <Box key={index}>
          {r.clientId && !r.delete && r.relationId !== props.indexId && (
            <Box>
              {viewToClientData(r, index)}
              <FormElement
                dialog={true}
                groupId={groupId}
                title={context.i18n.relationShip}
                style={FormFieldStyle.NORMAL}
                type={FormFieldType.DROPDOWN}
                value={r.type}
                required
                id={client.id + '-relation-select'}
                onChange={(val: any) => {
                  if (val !== '') {
                    const newClient = client;

                    newClient.genoGrammRelations[index] = {
                      ...newClient.genoGrammRelations[index],
                      type: val,
                    };

                    props.setClient({ ...newClient });
                  }
                }}
                fieldOptions={Object.keys(GenoGrammRelationType).map((r) => {
                  return {
                    label: context.i18n[r],
                    key: r,
                  };
                })}
                noNoneOption
              />
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};
