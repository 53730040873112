import {
  faPlus,
  faPenToSquare,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip, IconButton, Paper } from '@mui/material';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import moment from 'moment';
import { FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { ReportApi } from '../../../../api';
import { AppContext } from '../../../../hooks/context';
import { ReportDatagridElement } from '../../../../types';
import { parseDateTimeString, queryClient } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { AddReportDialog } from '../../../dialogs';
import ReportFilterToolbar from './report-filter-toolbar';
import { getSanitizedFileName } from '../../../../utils/core/misc-utils';

interface ReportTableProps {
  supervisorId: string | undefined;
  name: string | undefined;
}

const ReportTable: FC<ReportTableProps> = (props) => {
  const context = useContext(AppContext);

  const [creationDate, setCreationDate] = useState<moment.Moment>(
    moment().subtract(1, 'month').startOf('month'),
  );
  const [supervisorIds, setSupervisorIds] = useState([]);
  const [templateId, setTemplateId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rows, setRows] = useState<ReportDatagridElement[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [reportId, setReportId] = useState<any>();

  const { isLoading } = useQuery(
    [
      'reports',
      pageSize,
      pageNum,
      sortModel,
      creationDate,
      supervisorIds,
      templateId,
    ],
    () => {
      const data: any = {
        pageNum,
        pageSize,
        sortModel,
        creationDate,
        userIds: supervisorIds,
        clientId: context.clientId,
        templateId,
      };
      return ReportApi.listReports(context.authToken, data);
    },
    {
      enabled: context.authToken !== undefined,
      onSuccess: (res) => {
        setRows(
          res.data.reports.map((report: any) => {
            return {
              id: report.id,
              supervisor: report.supervisor.name,
              creationDate: parseDateTimeString(report.creationDate),
              supervisorId: report.supervisor.id,
              type: report.type,
            };
          }),
        );
        setRowCount(res.data.totalCount);
      },
      onError: (e) => {
        console.error(e);
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onExport = async (reportId: any) => {
    const res = await ReportApi.export(context.authToken, reportId);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${moment().format('DD_MM_YYYY')}_${
      context.i18n.report
    }_${getSanitizedFileName(props.name)}.docx`;
    a.click();
  };

  const tableColumns: Array<GridColDef> = [
    {
      field: 'supervisor',
      headerName: context.i18n.supervisor,
      flex: 1,
      hideable: false,
    },
    {
      field: 'creationDate',
      headerName: context.i18n.creationDate,
      flex: 1,
      hideable: true,
    },
    {
      field: 'type',
      headerName: context.i18n.type,
      flex: 1,
      hideable: true,
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
          <Tooltip title={context.i18n.addReport}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => {
                setReportId(undefined);
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => (
        <Box>
          <Tooltip title={context.i18n.download}>
            <IconButton
              disableRipple
              color="primary"
              id={'download-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() => onExport(params.id)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.editReport}>
            <IconButton
              disableRipple
              color="primary"
              id={'edit-button-' + params.id}
              sx={{ height: 39 }}
              onClick={() => {
                setOpen(true);
                setReportId(params.id);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        maxHeight: '80vh',
        m: 3,
        mt: 0,
        flexGrow: 1,
        overflowY: 'auto',
        borderRadius: 0,
      }}
    >
      <DataGridPro
        sx={{
          m: 2,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          display: 'flex',
        }}
        slotProps={{
          toolbar: {
            creationDate,
            supervisorIds,
            setCreationDate,
            setSupervisorIds,
            templateId,
            setTemplateId,
          },
        }}
        slots={{
          toolbar(props: any) {
            return ReportFilterToolbar(props);
          },
        }}
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        columnBufferPx={4}
        loading={isLoading}
        // Pagination settings
        paginationMode="server"
        onPaginationModelChange={(model) => {
          setPageNum(model.page);
          setPageSize(model.pageSize);
        }}
        paginationModel={{ pageSize, page: pageNum }}
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      <AddReportDialog
        open={open}
        onClose={() => {
          queryClient.invalidateQueries('reports');
          setOpen(false);
        }}
        supervisorId={props.supervisorId}
        reportId={reportId}
      />
    </Paper>
  );
};

export default ReportTable;
